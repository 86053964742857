// @flow
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNotify, hideNotification } from 'react-admin';
import Button from '@material-ui/core/Button';
import styled from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Layout as AppLayout, Footer, Container } from 'components';
import { Input, TextField, InputAdornment, IconButton, FormControl, FormHelperText } from '@material-ui/core';
import { Eye, EyeClose} from 'components/icons';
import type { ComponentType } from 'react';
import { isValidEmail, isNumber } from 'helpers';
import PasswordMeter from '../../components/forms/PasswordMeter';
import useHasInternet from 'hooks/useHasInternet';
import cognito from '../../helpers/apis/cognito';

const Layout: ComponentType<*> = styled.div`
  min-width: 320px;
  max-width: 440px;
  margin: 0 auto;
`;

const Form: ComponentType<*> = styled.form`
  display: flex;
  flex-direction: column;
  background-color: var(--backgroundPaper);
  border-radius: 2px;
  border-top: 5px solid #082f49;
  margin-top: calc(var(--spacing) * 10);
  padding: calc(var(--spacing) * 5);
  && {
    > div {
      margin: 20px 0;
    }
  }
  input::-ms-reveal,
  input::-ms-clear {
    display: none;
  }
  .box {
    margin: 0 !important;
  }
`;

const emailInvalidErrMsg = 'Email address is invalid.';

const UserNew = () => {
  const notify = useNotify();
  const dispatch = useDispatch();
  const [emailError, setEmailError] = useState(false);
  const [confirmEmailError, setConfirmEmailError] = useState(false);
  const [newUserPayload, setNewUserPayload] = useState({
    registration_code: '',
    email: '',
    confirm_email: '',
    first_name: '',
    last_name: '',
    phone_number: '',
    phone_number_ext: '',
    cell_number: '',
    password: '',
    confirm_password: ''
  });
  const hasInternet = useHasInternet();

  const passwordIsValid = () => {
    const passwordValid =
      passwordMatch() &&
      newUserPayload.password.trim() !== '' &&
      newUserPayload.confirm_password.trim() !== '';
    if (!passwordValid) {
      return false;
    }
    return true;
  };

  const passwordMatch = () => {
    return newUserPayload.password === newUserPayload.confirm_password
  }

  const showPasswordMatchError =
    !passwordMatch() && newUserPayload.password.trim() !== '' && newUserPayload.confirm_password.trim() !== '';

  const emailIsValid = () => {
    const isEmailsMatch = newUserPayload.email === newUserPayload.confirm_email;
    const isEmailValid = isValidEmail(newUserPayload.email);
    const isConfirmEmailValid = isValidEmail(newUserPayload.confirm_email);
    const emailValid = isEmailsMatch && isEmailValid && isConfirmEmailValid;
    if (!isEmailValid) setEmailError(emailInvalidErrMsg);
    if (!isConfirmEmailValid) setConfirmEmailError(emailInvalidErrMsg);
    if (!emailValid) {
      notify('Please provide a valid email and make sure emails match');
      return false;
    }
    return true;
  };

  const validateField = field => {
    const fieldValid = newUserPayload[field].trim() !== '';
    if (!fieldValid) {
      notify(`${field} can't be empty`);
      return false;
    }
    return true;
  };

  const validatePasswordMaxLength = field => {
    const fieldValid = newUserPayload[field].length;
    if (fieldValid > 128) {
      notify(`Please set a ${field} less than 128 characters`);
      return false;
    }
    return true;
  };

  const formIsValid = () =>
    passwordIsValid() &&
    emailIsValid() &&
    validateField('registration_code') &&
    validateField('first_name') &&
    validateField('last_name') &&
    validatePasswordMaxLength('password');

  const [loading, setLoading] = useState(false);

  const handleCancel = e => {
    dispatch(hideNotification());
    window.location.href = `/#login`;
  };

  const [passwordScore, setPasswordScore] = useState(0);
  const [showPassword, setShowPassword] = useState(false);

  const submit = e => {
    e.preventDefault();
    if (formIsValid()) {
      setLoading(true);
      cognito
        .registerUser(newUserPayload)
        .then(response => {
          notify(response.data.description);
          window.location.href = `/#login?email=${newUserPayload.email}`;
        })
        .catch(err => {
          hasInternet(
            () => {
              notify(
                err?.response?.data?.message ||
                  err?.response?.data?.description ||
                  'an error occurred while creating the user',
                'warning'
              );
            },
            () => {}
          );
        })
        .finally(() => setLoading(false));
    }
  };

  const onChange = e => {
    const { name, value } = e.target;
    setNewUserPayload({ ...newUserPayload, [name]: value });

    if (name === 'email' && emailError) {
      if (!value || isValidEmail(value)) setEmailError(false);
      else setEmailError(emailInvalidErrMsg);
    }

    if (name === 'confirm_email' && confirmEmailError) {
      if (!value || isValidEmail(value)) setConfirmEmailError(false);
      else setConfirmEmailError(emailInvalidErrMsg);
    }
  };

  const isSubmitDisabled =
    newUserPayload.registration_code.trim() === '' ||
    newUserPayload.first_name.trim() === '' ||
    newUserPayload.last_name.trim() === '' ||
    newUserPayload.email.trim() === '' ||
    newUserPayload.confirm_email.trim() === '' ||
    newUserPayload.password.trim() === '' ||
    newUserPayload.confirm_password.trim() === '' ||
    !passwordMatch() ||
    passwordScore<3 ;

  const headerStyle = { color: '#082f49', 'text-align': 'left' };

  return (
    <AppLayout sidebar={null} background="paper" centered footer={<Footer />}>
      <Layout>
        <Form onSubmit={submit} noValidate>
          <input type="hidden" value="prayer" />
          <h2 style={headerStyle}>Create Account</h2>
          <Input placeholder="Registration Code *" name="registration_code" onChange={onChange} />

          <h3 style={headerStyle}>Personal Info</h3>
          <Input fullWidth placeholder="First name *" onChange={onChange} name="first_name" />
          <Input fullWidth placeholder="Last name *" onChange={onChange} name="last_name" />

          <Container.Grid sm={12} xs={12}>
            <Container.Grid sm={8} xs={8}>
              <Input
                fullWidth
                placeholder="Phone number"
                onChange={onChange}
                onKeyPress={e => {
                  if (!isNumber(e.key)) {
                    e.preventDefault();
                  }
                }}
                inputProps={{
                  maxlength: 15
                }}
                name="phone_number"
              />
            </Container.Grid>
            <Container.Grid pl={1} sm={4} xs={4}>
              <Input
                fullWidth
                placeholder="Ext"
                style={{ display: 'inline-block' }}
                onChange={onChange}
                name="phone_number_ext"
                type="text"
                onKeyPress={e => {
                  if (!isNumber(e.key)) {
                    e.preventDefault();
                  }
                }}
                inputProps={{
                  maxlength: 5
                }}
              />
            </Container.Grid>
          </Container.Grid>

          <Input
            fullWidth
            onKeyPress={e => {
              if (!isNumber(e.key)) {
                e.preventDefault();
              }
            }}
            inputProps={{
              maxlength: 15
            }}
            placeholder="Mobile number"
            onChange={onChange}
            name="cell_number"
          />

          <h3 style={headerStyle}>Account</h3>
          <FormControl error={emailError}>
            <Input
              fullWidth
              type="email"
              placeholder="Email *"
              onChange={onChange}
              name="email"
              inputProps={{ maxlength: 199 }}
              autoComplete="email"
            />
            {emailError && <FormHelperText>{emailError}</FormHelperText>}
          </FormControl>
          <FormControl error={confirmEmailError}>
            <Input
              fullWidth
              placeholder="Confirm Email *"
              onChange={onChange}
              name="confirm_email"
              inputProps={{ maxlength: 199 }}
            />
            {confirmEmailError && <FormHelperText>{confirmEmailError}</FormHelperText>}
          </FormControl>
          <Input
            fullWidth
            autoComplete="new-password"
            placeholder="Password *"
            onChange={onChange}
            type={showPassword ? 'text' : 'password'}
            name="password"
            inputProps={{
              maxLength: 128
            }}
            error={newUserPayload.password && passwordScore < 3}
          />
          {newUserPayload.password && (
            <PasswordMeter
              password={newUserPayload.password}
              onChangePassword={e => {
                setPasswordScore(e);
              }}
            />
          )}
          <TextField
            fullWidth
            placeholder="Verify Password *"
            onChange={onChange}
            type={showPassword ? 'text' : 'password'}
            name="confirm_password"
            inputProps={{
              maxLength: 128
            }}
            InputProps={{
              endAdornment:
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)}>
                  {showPassword ? <Eye /> : <EyeClose />}
                </IconButton>
              </InputAdornment>
            }}
            error={showPasswordMatchError}
            helperText={showPasswordMatchError && 'Passwords do not match.'}
          />
          <Container.Grid container justifyContent="center" sm={12} xs={12}>
            <Container.Grid item pr={1}>
              <Button
                disabled={loading || isSubmitDisabled}
                style={{
                  backgroundColor: `${isSubmitDisabled ? '#ccc' : '#082f49'}`,
                  color: '#fff',
                  cursor: `${isSubmitDisabled ? 'not-allowed' : 'pointer'}`,
                  'pointer-events': 'auto'
                }}
                type="submit"
                variant="contained"
              >
                &nbsp;&nbsp;Submit
                {loading && <CircularProgress size={18} thickness={2} />}
              </Button>
            </Container.Grid>
            <Container.Grid item pl={1}>
              <Button onClick={handleCancel} style={{ backgroundColor: '#082f49' }} variant="contained" color="primary">
                &nbsp;&nbsp;Cancel
              </Button>
            </Container.Grid>
          </Container.Grid>
        </Form>
      </Layout>
    </AppLayout>
  );
};

export default UserNew;
