import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNotify } from 'react-admin';
import { Checkbox, FormControlLabel, Box, Divider } from '@material-ui/core';
import { Save } from 'components/icons';
import { Button, Typography, TextField, LoadingStyled } from 'components';
import clients from 'helpers/apis/clients';
import { encodeId } from 'helpers';

const AdminSRACapabilities = ({ client_id }) => {
  const notify = useNotify();
  const [limit, setLimit] = useState(1);
  const [noLimit, setNoLimit] = useState(false);
  const [originalMaxRetakes, setOriginalMaxRetakes] = useState();
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false)
  const clientId = encodeId(client_id);
  const dirty = () => {
    if (noLimit) return originalMaxRetakes !== null;
    return originalMaxRetakes !== limit;
  }
  const disabled = !dirty() || saving || loading;

  const handleChangeLimit = e => {
    const val = parseInt(e.target.value);
    if (val > 10) return;
    setLimit(val);
  };

  const handleChangeNoLimit = e => {
    setNoLimit(e.target.checked);
  };

  const onSaveSRALimit = e => {
    setSaving(true);
    const data = { sra_max_retakes: noLimit ? null : limit };

    clients
      .setSraAnnualLimit(clientId, data)
      .then(res => {
        setOriginalMaxRetakes(noLimit ? null : limit);
        const message = res?.data?.description;
        if (message) notify(message);
      })
      .catch(err => {
        enqueueAlertSnackbar(
          'Error: Unable to save SRA limit',
          { props: { severity: 'error' } }
        );
      })
      .finally(() => {
        setSaving(false);
      })
  };

  useEffect(() => {
    setLoading(true);

    clients
      .getSraAnnualLimit(clientId)
      .then(res => {
        const limitVal = res?.data?.sra_max_retakes;
        setOriginalMaxRetakes(limitVal);
        if (limitVal) setLimit(limitVal);
        else if (limitVal === null) setNoLimit(true);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [clientId]);

  if (loading) return <LoadingStyled />;

  return (
    <Box width="100%">
      <Typography.p fontSize={14} mt="0px" mb={2}>
        Annual SRA Limit
      </Typography.p>

      <Box display="flex" alignItems="center" mb={2}>
        <Box width={110} mr={3}>
          <TextField
            size="small"
            type="number"
            name="limit"
            integerOnly
            fullWidth
            inputProps={{
              min: 1,
              max: 10
            }}
            disabled={noLimit}
            value={limit}
            onChange={handleChangeLimit}
          />
        </Box>

        <FormControlLabel
          control={<Checkbox checked={noLimit} onChange={handleChangeNoLimit} name="nolimit" color="primary" />}
          label="No Limit"
        />
      </Box>

      <Divider />

      <Box display="flex" justifyContent="flex-end" p={1} pb={0}>
        <Button
          backgroundColor="colorSystemInfo"
          backgroundColorHover="colorSystemInfoHover"
          disabled={disabled}
          onClick={onSaveSRALimit}
        >
          <Save color="colorCommonWhite" mr={1.2} size={1.3} />
          Save
        </Button>
      </Box>
    </Box>
  );
};

AdminSRACapabilities.propTypes = {
  client_id: PropTypes.number.isRequired
};

export default AdminSRACapabilities;
