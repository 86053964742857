// @flow
import React, { type ComponentType, type Element, useState, useRef } from 'react';
import styled from 'styled-components';
import { useNotify } from 'react-admin';
import { Button, Tooltip } from '@material-ui/core';
import { TextField, Button as MUIButton, LazyIcon } from 'components';
import { dataProvider } from 'helpers';
import { useLocation, useFormData } from 'hooks';
import { useTableList } from './TableListContext';
import { Link, enqueueAlertSnackbar } from '@trustsecurenow/components-library';


type UpdatingCellTypes = {
  cellId: string,
  cellValue: string,
  cellConfig: {
    app?: string,
    tab?: string,
    field: string,
    id?: boolean
  }
};

const ButtonCaptialize: ComponentType<*> = styled(Button)`
  && {
    text-transform: initial;
    font-weight: initial;
  }
`;

const TagFormData: ComponentType<*> = styled.form`
  display: flex;
`;

const UpdatingCell = ({ cellId, cellValue, cellConfig }: UpdatingCellTypes): Element<*> => {
  const { app: appLocation, tab: tabLocation, item } = useLocation();
  const { dispatch: dispatchTable } = useTableList();
  const { setFormData } = useFormData();
  const [edit, setEdit] = useState(false);
  const [error, setError] = useState({
    show: false,
    message: ''
  });
  const notify = useNotify();
  const formRef = useRef(null);

  const onKeyDown = e => {
    if (e.keyCode === 13) onSubmit(e);
  }

  const onSubmit = e => {
    e.preventDefault();
    const fData = setFormData(formRef?.current);
    const { field } = cellConfig;

    if (!fData?.[field]?.trim()) {
      setError({
        ...error,
        show: true,
        message: 'Please fill out this field.'
      });
    } else {
      setError({
        ...error,
        show: false,
        message: ''
      });

      const { app: appConfig, tab: tabConfig, id } = cellConfig;
      const app = appConfig || appLocation;
      const tab = tabConfig || tabLocation;
      const setId = id ? cellId : item;
      const data = {
        [field]: fData?.[field]
      };

      dataProvider.update(app, `${tab}/${setId}`, data)
      .then(res => {
        enqueueAlertSnackbar(res.data, {
          props: {
            severity: 'success'
          }
        })
      })
      .finally(() => {
        setEdit(false);
        dispatchTable.setRefresh(true);
        setTimeout(() => {
          dispatchTable.setRefresh(false);
        }, 1000);
      })
    }
  };

  const onCancel = () => {
    setEdit(false);
    setError({
      ...error,
      show: false,
      message: ''
    });
  };

  return (
    <>
      {!edit ? (
        <Tooltip title="Click to edit" placement="right">
          <Link component="button" underline="none" onClick={() => setEdit(true)}>
            {cellValue}
          </Link>
        </Tooltip>
      ) : (
        <TagFormData onSubmit={e => e.preventDefault()} ref={formRef}>
          <TextField
            value={cellValue}
            name={cellConfig.field}
            size="small"
            error={error.show}
            helperText={error.show && error.message}
            inputProps={{ maxlength: 25 }}
            onKeyDown={onKeyDown}
          />
          <MUIButton
            onClick={e => onSubmit(e)}
            backgroundColor="colorSystemSuccess"
            backgroundColorHover="colorSystemSuccessHover"
            ml={2}
            mt={1}
            mb={error.show ? 3.5 : 1}
          >
            <LazyIcon component="Done" color="colorCommonWhite" mr={1} />
            Save
          </MUIButton>
          <MUIButton
            onClick={onCancel}
            backgroundColor="colorSystemDanger"
            backgroundColorHover="colorSystemDangerHover"
            ml={2}
            mt={1}
            mb={error.show ? 3.5 : 1}
          >
            <LazyIcon component="Close" color="colorCommonWhite" mr={1} size={0.7} />
            Cancel
          </MUIButton>
        </TagFormData>
      )}
    </>
  );
};

export default UpdatingCell;