import React from 'react';
import {
  Button,
  Container,
  CustomAlert,
  DateField,
  LazyIcon,
  LoadingStyled,
  SelectField,
  Switch,
  TextField
} from 'components';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Stack
} from '@trustsecurenow/components-library';
import { AccordionFooter, ConfirmModal } from '../../../apps/admin/components';
import { getStorage } from 'helpers';
import AdminCapabilitiesDWBA from './AdminCapabilitiesDWBA';
import AdminCapabilitiesInsurance from './AdminCapabilitiesInsurance';
import AdminCapabilitiesNotes from './AdminCapabilitiesNotes';
import AdminCapabilitiesSraDocuments from './AdminCapabilitiesSraDocuments';
import AdminMoveClient from './AdminMoveClient';
import useAdminCapabilities from './useAdminCapabilities';
import AdminSRACapabilities from './AdminSRACapabilities';

import COMMON_CONST from 'apps/shared/constants';

const AdminCapabilities = () => {
  const {
    record,
    dwba,
    loading,
    loadingDWBA,
    dispatch,
    trainingsOptions,
    productCodeOptions,
    isProductCodeOptionsLoading,
    validLink,
    openDialog,
    openReset,
    useNoteList,
    accountTypes,
    productTypes,
    UTproductType,
    campaignUsed
  } = useAdminCapabilities();

  const {
    clearDwba,
    onChange,
    onChangeAccountType,
    onChangeActiveStatus,
    onChangeCourse,
    onChangeLink,
    onChangePrice,
    onChangeProductType,
    onConfirmPriceOption,
    onMoveClient,
    onResetCampaign,
    onRefetchClientInfo,
    save,
    saveClientInfo,
    setOpenDialog,
    setOpenReset,
    disableSaveClientInfo,
    disableSaveAccountInfo
  } = dispatch;

  const partnerName = getStorage('maskPartnerName');

  const { MAX_CAMPAIGNS_USED } = COMMON_CONST;

  const { isLoading, refetch, data: notesData, dispatch: dispatchNotes, total } = useNoteList;

  const productCode = ['BSNP', 'BSNPv2', 'EBPPC-0', 'DEMO'].includes(record?.pax8_product_code)
                    ? record?.pax8_product_code
                    : record?.pax8_product_code?.toLowerCase();

  const current_course = trainingsOptions.length && trainingsOptions[0].value
  const noAccessToSra = ['Unlimited Cybersecurity Training', 'EVA MD'].includes(record?.product_type);
  const showSraSection = record?.new_sra && !noAccessToSra;

  if (loading) return <LoadingStyled />;

  return (
    <Stack direction="column" spacing={1}>
      <Accordion sx={{ '&:before': { background: 'none' },}}>
        <AccordionSummary sx={{ '&.Mui-expanded': { color: 'var(--colorSystemInfo)' },}}>
          <Typography variant='subtitle1' color="inherit">Client Information</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Container.Grid container md={12} spacing={2}>
            <Container.Grid pl={1}>
              <Switch
                label="Active Status"
                value={Boolean(record?.active)}
                checked={record?.active}
                name="active"
                onChange={({ target: { name, checked } }) => onChangeActiveStatus(name, checked)}
              />
            </Container.Grid>

            <Container.Grid item md={5} lg={4}>
              <TextField label="Partner Name" value={partnerName} fullWidth disabled />
            </Container.Grid>

            <Container.Grid item md={5} lg={4}>
              <TextField
                name="name"
                label="Client Name"
                value={record?.name}
                onChange={({ target: { name, value } }) => onChange(name, value)}
                fullWidth
              />
            </Container.Grid>

            <Container.Grid item>
              <AdminMoveClient clientName={record?.name} onMoveClient={onMoveClient} />
            </Container.Grid>

            <Container.Grid item md={5} lg={4} mt={1}>
              <SelectField
                name="is_real"
                label="Client Type"
                value={Boolean(record?.is_real)}
                onChange={({ target: { name, value } }) => onChange(name, value)}
                fullWidth
                choices={[
                  { value: true, label: 'Production' },
                  { value: false, label: 'Test' },
                ]}
              />
            </Container.Grid>

            <Container.Grid item md={3} lg={2}>
              <TextField
                label="Active/Registered Users"
                value={`${record?.no_of_employees}/${record?.user_count}`}
                disabled
                fullWidth
              />
            </Container.Grid>

            <Container.Grid item md={2} lg={2} mt={1}>
              <DateField name="created" label="Created Date" value={record?.created} disabled fullWidth />
            </Container.Grid>

            <Container.Grid item lg={4}></Container.Grid>

            <Container.Grid item md={5} lg={4}>
              <TextField
                name="link"
                label="Client Link"
                value={record?.link}
                onChange={({ target: { name, value } }) => onChangeLink(name, value)}
                fullWidth
                error={!validLink}
                helperText={!validLink ? 'Please enter a valid link' : 'Please include ”http://”'}
              />
            </Container.Grid>
            <AccordionFooter onSubmit={saveClientInfo} disabled={disableSaveClientInfo() } />
          </Container.Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion sx={{ '&:before': { background: 'none' },}}>
        <AccordionSummary sx={{ '&.Mui-expanded': { color: 'var(--colorSystemInfo)' },}}>
          <Typography variant='subtitle1' color="inherit">Account Information</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Container.Grid container md={12} spacing={2}>
            <Container.Grid item md={12} lg={12} mb={2}>
              <CustomAlert
                type="info"
                variant="outlined"
                icon={<LazyIcon component="Alert" size={1.5} color="colorSystemInfo" />}
              >
                Any changes to the Product Type or Product Code will not impact billing. Any product changes should be
                handled through the Products tab
              </CustomAlert>
            </Container.Grid>

            <Container.Grid item md={5} lg={4}>
              <SelectField
                name="account_type"
                label="Account Type"
                value={record?.account_type}
                onChange={({ target: { name, value } }) => onChangeAccountType(name, value)}
                choices={accountTypes}
                fullWidth
              />
            </Container.Grid>

            <Container.Grid item md={5} lg={4}>
              {(record?.product_type === 'HIPAA Compliance' ||
                record?.product_type === 'Unlimited Cybersecurity Training') && (
                <Switch
                  label="Special Pricing Option"
                  value={Boolean(record?.special_pricing_option)}
                  checked={record?.special_pricing_option}
                  name="special_pricing_option"
                  onChange={({ target: { checked } }) => onChangePrice(checked)}
                />
              )}
            </Container.Grid>

            <Container.Grid item md={2} lg={4}></Container.Grid>

            <Container.Grid item md={5} lg={4} mt={1}>
              <SelectField
                name="product_type"
                label="Product Type"
                allowEmpty
                value={record?.product_type}
                onChange={({ target: { name, value } }) => onChangeProductType(name, value)}
                choices={record?.account_type === 'Unlimited Training' ? UTproductType : productTypes}
                fullWidth
              />
            </Container.Grid>

            <Container.Grid item md={5} lg={4} mt={1}>
              <SelectField
                name="pax8_product_code"
                allowEmpty
                label="Product Code"
                value={productCode}
                choices={productCodeOptions}
                onChange={({ target: { name, value } }) => onChange(name, value)}
                fullWidth
                disabled={isProductCodeOptionsLoading}
              />
            </Container.Grid>

            <Container.Grid item md={2} lg={4}></Container.Grid>

            <Container.Grid item md={5} lg={4}>
              <TextField
                name="portal_client_id"
                label="Portal Client ID"
                value={record?.portal_client_id}
                disabled
                fullWidth
              />
            </Container.Grid>

            <Container.Grid item md={5} lg={4}>
              <TextField
                name="portal_partner_id"
                label="Portal Partner ID"
                value={record?.portal_partner_id}
                disabled
                fullWidth
              />
            </Container.Grid>

            <Container.Grid item md={2} lg={4}></Container.Grid>

            <Container.Grid item md={5} lg={4}>
              <TextField
                name="pax8_subscription_id"
                label="Billing Subscription ID"
                value={record?.pax8_subscription_id}
                onChange={({ target: { name, value } }) => onChange(name, value)}
                fullWidth
              />
            </Container.Grid>

            <Container.Grid item md={3} lg={2}>
              <TextField
                name="pax8_customer_id"
                label="Billing Customer ID"
                value={record?.pax8_customer_id}
                onChange={({ target: { name, value } }) => onChange(name, value)}
                fullWidth
              />
            </Container.Grid>

            <Container.Grid item md={2} lg={2}>
              <TextField
                name="pax8_partner_id"
                label="Billing Partner ID"
                value={record?.pax8_partner_id}
                onChange={({ target: { name, value } }) => onChange(name, value)}
                fullWidth
              />
            </Container.Grid>

            <Container.Grid item md={2} lg={4}></Container.Grid>

            <Container.Grid item md={5} lg={4}>
              <SelectField
                name="moodle_course_id"
                label="Training Course"
                value={current_course}
                choices={trainingsOptions}
                onChange={({ target: { name, value } }) => onChangeCourse(name, value)}
                fullWidth
              />
            </Container.Grid>

            <AccordionFooter onSubmit={save} disabled={disableSaveAccountInfo()} />
          </Container.Grid>
        </AccordionDetails>
      </Accordion>

      {record?.insurance ? (
        <Accordion sx={{ '&:before': { background: 'none' },}}>
          <AccordionSummary sx={{ '&.Mui-expanded': { color: 'var(--colorSystemInfo)' },}}>
            <Typography variant='subtitle1' color="inherit">Insurance Information</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <AdminCapabilitiesInsurance />
          </AccordionDetails>
        </Accordion>
      ) : null}


      {record?.product_type !== 'HIPAA Compliance' ? (
        <Accordion sx={{ '&:before': { background: 'none' },}}>
          <AccordionSummary sx={{ '&.Mui-expanded': { color: 'var(--colorSystemInfo)' },}}>
            <Typography variant='subtitle1' color="inherit">Dark Web Breach Assessment</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <AdminCapabilitiesDWBA data={dwba} loading={loadingDWBA} clearDwba={clearDwba} />
          </AccordionDetails>
        </Accordion>
      ): null}

      {showSraSection ? (
        <Accordion sx={{ '&:before': { background: 'none' },}}>
          <AccordionSummary sx={{ '&.Mui-expanded': { color: 'var(--colorSystemInfo)' },}}>
            <Typography variant='subtitle1' color="inherit">SRA</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <AdminSRACapabilities client_id={record?.portal_client_id} />
          </AccordionDetails>
        </Accordion>
      ) : null}

      {record.account_type === "Unlimited Training" ? (
        <Accordion sx={{ '&:before': { background: 'none' },}}>
          <AccordionSummary sx={{ '&.Mui-expanded': { color: 'var(--colorSystemInfo)' },}}>
            <Typography variant='subtitle1' color="inherit">Phishing</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Container.Grid item md={8} lg={7} pr={2} alignItems="center">
              <Typography variant='subtitle1' mr={2} mt={0.4} mb={0.4}>
                Campaign Used
              </Typography>
              <TextField
                size="small"
                value={`${campaignUsed}/${MAX_CAMPAIGNS_USED}`}
                disabled
                style={{ width: 55 }}
                inputProps={{
                  style: { textAlign: 'center' }
                }}
              />
              <Button onClick={setOpenReset} color="colorBaseBase" ml={2}>
                Reset Campaign
              </Button>
            </Container.Grid>
          </AccordionDetails>
        </Accordion>
      ) : (
        <></>
      )}

      {!['EVA MD', 'Unlimited Cybersecurity Training'].includes(record?.product_type) ? (
        <AdminCapabilitiesSraDocuments
          sraStatus={{ consulting: record?.ra_consulting, date_completed: record?.ra_date_completed }}
          onRefetch={onRefetchClientInfo}
        />
      ) : null}

      <Accordion sx={{ '&:before': { background: 'none' },}}>
        <AccordionSummary sx={{ '&.Mui-expanded': { color: 'var(--colorSystemInfo)' },}}>
          <Typography variant='subtitle1' color="inherit">Notes</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Container.Grid container md={12} spacing={2}>
            <Container.Grid md={8} lg={6} pl={1} pb={2}>
              <TextField
                name="notes"
                label="Enter information that maybe can be important"
                value={record?.notes}
                onChange={({ target: { name, value } }) => onChange(name, value)}
                fullWidth
                multiline
                rows={3}
              />
            </Container.Grid>

            {notesData?.length > 0 && (
              <AdminCapabilitiesNotes data={notesData} isLoading={isLoading} total={total} dispatch={dispatchNotes} />
            )}

            <AccordionFooter onSubmit={() => save('notes')} disabled={!record?.notes?.trim()} />
          </Container.Grid>
        </AccordionDetails>
      </Accordion>

      <ConfirmModal
        onClose={() => setOpenDialog(false)}
        onSubmit={onConfirmPriceOption}
        config={{
          open: openDialog,
          title: 'Special price option',
          message:
            'Confirm that you would like the HIPAA Compliance 1-10 pricing option to be available for this client'
        }}
      />

      <ConfirmModal
        onClose={() => setOpenReset(false)}
        onSubmit={onResetCampaign}
        config={{
          open: openReset,
          title: 'Reset Campaign',
          message: 'Are you sure you would like to reset the phishing campaign for this client?'
        }}
        disableBackdropClick
        disableEscapeKeyDown
      />
    </Stack>
  );
};

export default AdminCapabilities;
