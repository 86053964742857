/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
// @flow
import axios from 'axios';
import { NOTIFICATION_CUSTOM_EVENT, DISPATCH_CUSTOM_EVENT } from 'conf/constants';
import paginationConfig from '../conf/state/system/table/pagination';
import { getIdByResource, getId, isObjectEmpty, hasKey, isMaskModeEnabled } from './utils';
import { getStorage, clearStorage, setStorage } from './storage';
import prefix from './subdomainMappings';

const env = process.env.REACT_APP_BUILD_ENV;
const base = process.env.REACT_APP_BASE_URL;

const dispatchCustomNotificationEvent = err => {
  const event = new CustomEvent(NOTIFICATION_CUSTOM_EVENT, { detail: err });
  document.body && document.body.dispatchEvent(event);
  throw err;
};

const dispatchCustomDispatcherEvent = dispatchArgs => {
  // dispatching CustomEvent to Layout.js listener so it can handle redux dispatcher globaly on app
  const event = new CustomEvent(DISPATCH_CUSTOM_EVENT, { detail: dispatchArgs });
  document.body && document.body.dispatchEvent(event);
};

export const APIUrl: Function = (app?: string = 'login', tab?: string = 'default'): string => {
  const tabName = tab.split('/')[0];
  const t = hasKey(prefix[app], tabName) ? tabName : 'default';
  // if (app==='myDashboard' && (tabName ==='DashboardQuizModal_v2' || tabName ==='DashboardQuizModal' || tabName==='DashboardQuizModalAddMark')){
  //   return `http://127.0.0.1:5000`;
  // }
  return `https://${prefix[app][t]}.${String(base)}/${String(env)}`;
};

export const getApiULR = (app: string, tab: string): string | null => {
  const [newTab, item] = tab.split('/');
  const id = item && item !== 'null' ? item : getIdByResource(app, tab);
  if (app !== 'user' && !id) return null;
  let params =
    app === 'clients' && newTab === 'list'
      ? `${app}/v1/${tab}`
      : app === 'user' && tab !== 'contactUs' && tab !== 'profile'
      ? `${app}/${tab}`
      : `${app}/${newTab.replace('-', '/')}/${id.replace('-', '/')}`;
  let url = '';
  switch (app) {
    case 'admin':
    case 'user':
    case 'myDashboard':
    case 'clients':
    case 'myCompany':
    case 'partnerProfile': {
      url = APIUrl(app, tab);
      break;
    }

    default: {
      params = `${params}.json`;
      break;
    }
  }
  return `${url}/${params}`;
};

const getParams = ({
  pagination,
  sort,
  filter,
  ...params
}: {
  pagination?: {
    page: number,
    perPage: number
  },
  sort?: {
    field: string,
    order: string
  },
  filter?: Object,
  _sort?: string
}) => {
  const p = {};
  p._start = params._start ? params._start : pagination ? (pagination.page - 1) * pagination.perPage : 0;
  p._end = params._end ? params._end : pagination ? pagination.page * pagination.perPage : paginationConfig.rowsPerPage;
  p._sort = sort ? sort.field : 'name';
  p._sort = Object.prototype.hasOwnProperty.call(params, '_sort') ? params._sort : p._sort;
  p._order = sort ? sort.order.toUpperCase() : 'ASC';
  p._order = Object.prototype.hasOwnProperty.call(params, '_order') ? params._order : p._order;
  if (pagination && pagination.page !== 1) {
    p._start += 1;
    p._end += 1;
  }
  p.timestamp_cache = new Date().getTime();
  console.log(filter);
  if (filter && !isObjectEmpty(filter)) {
    const arF = Object.fromEntries(
      Object.entries(filter).map(entry => (Array.isArray(entry[1]) ? [entry[0], entry[1].join(' ')] : entry))
    );
    p._filter = JSON.stringify(arF)
      .replace(/[{}]/g, '')
      .replace(/[""]/g, '');
  }
  return { ...params, ...p };
};

const getOptions = (
  resource: string,
  tab: string,
  method: string,
  params?: Object = {},
  useDefaultParams?: boolean = true
): Object => {
  console.log('App: ', resource);
  console.log('Current Tab: ', tab.replace('-', '/'));
  const url = getApiULR(resource, tab);
  console.log('API URL: ', url);
  if (!url) return {};
  console.log('Params: ', params);
  const newParams = useDefaultParams ? getParams(params) : params;
  console.log('New Params: ', newParams);
  return {
    url,
    method,
    headers: {
      Authorization: getStorage('idToken', true),
      'Content-Type': 'application/json'
    },
    params: newParams
  };
};

const getTotal = (headers: Object, data: Array<*>) =>
  Object.prototype.hasOwnProperty.call(headers, 'x-total-count') ? headers['x-total-count'] : data.length;

const dataProvider = {};

dataProvider.getList = (resource: string, tab: string, params?: Object) => {
  const isTab = typeof tab === 'string';
  const newTab = isTab ? tab : 'list';
  const newParams = isTab ? params : tab;
  console.group(`%cAPI REQUEST GETLIST ${resource}/${newTab.replace('-', '/')}`, 'font-size: medium; color: yellow');
  const options = getOptions(resource, newTab, 'GET', newParams);
  console.log(tab);
  console.log(options);
  console.groupEnd();
  if (!options) return Promise.resolve({ data: {}, total: 0 });
  return axios(options)
    .then(resp => {
      const { data, headers } = resp;
      let newData = data;
      if (resource === 'myCompany' && tab === 'employees') {
        newData = {
          [getIdByResource('myCompany')]: data
        };
      }
      const result = {
        data: newData,
        total: getTotal(headers, data)
      };
      if (resource === 'clients' && typeof tab === 'object') {
        result.data = newData.clients ?? newData;
        setStorage(
          'disabled_system_email_clients_count',
          newData?.is_enterprise_partner ? newData.disabled_system_email_clients_count ?? 0 : 0,
          true
        );
        dispatchCustomDispatcherEvent({
          type: 'BSN_SET_ANY',
          payload: {
            user: {
              profile: {
                is_enterprise_partner: newData?.is_enterprise_partner ?? 0,
                disabled_system_email_clients_count: newData?.is_enterprise_partner
                  ? newData.disabled_system_email_clients_count ?? 0
                  : 0
              }
            }
          }
        });
      }
      return result;
    })
    .catch(error => {
      dispatchCustomNotificationEvent(error);
      return error;
    })
    .finally(() => {
      // Property to control loading after enable or disable mask partner
      const isLoading = getStorage('showLoading', true) === 'true';
      if (resource === 'clients' && isLoading) setStorage('showLoading', false, true);
    });
};

/**
 * Has the same implementation as dataProvider.getList method,
 * except that after getting options from getOtions method,
 * It replaces the options.params with the params object passed by the caller,
 * So basically it doesn't enforce a default value to params when the caller doesn't provide params
 */
dataProvider.getListWithoutDefaultPagination = (resource, tab, params = {}) => {
  const isTab = typeof tab === 'string';
  const newTab = isTab ? tab : 'list';
  const newParams = isTab ? params : tab;
  console.group(
    `%cAPI REQUEST GETLIST_WITHOUT_DEFAULT_PAGINATION ${resource}/${newTab.replace('-', '/')}`,
    'font-size: medium; color: yellow'
  );
  const options = getOptions(resource, newTab, 'GET', newParams);
  options.params = { ...params };
  console.log(tab);
  console.log(options);
  console.groupEnd();
  if (!options) return Promise.resolve({ data: {}, total: 0 });
  return axios(options)
    .then(resp => {
      const { data, headers } = resp;
      let newData = data;
      if (resource === 'myCompany' && tab === 'employees') {
        newData = {
          [getIdByResource('myCompany')]: data
        };
      }
      return {
        data: newData,
        total: getTotal(headers, data)
      };
    })
    .catch(error => {
      dispatchCustomNotificationEvent(error);
      return error;
    });
};

dataProvider.getOne = (resource: string, tab: string | Object, params?: Object = {}) => {
  console.group(`%cAPI REQUEST GETONE ${resource}/${tab}`, 'font-size: medium; color: yellow');
  const options = getOptions(resource, tab, 'GET', params, false);
  console.groupEnd();
  return axios(options)
    .then(({ data: newData }) => ({ data: newData, total: 1 }))
    .catch(error => {
      dispatchCustomNotificationEvent(error);
      return error?.response?.data;
    });
};

dataProvider.getMany = (resource: string, params: Object) => Promise;

dataProvider.getManyReference = (resource: string, params: Object) => Promise;

dataProvider.create = (resource: string, params: Object) => {
  console.group(`%cAPI REQUEST CREATE ${resource}`, 'font-size: medium; color: #8458B3');
  const maskMode = isMaskModeEnabled(resource);
  const keyId = maskMode ? 'maskPartnerId' : 'partnerId';
  const options = getOptions(resource, `add/${getId(keyId)}`, 'POST');
  const { data } = params;
  console.log('Resource: ', resource);
  console.log('Data: ', data);
  console.log('Options: ', options);
  console.groupEnd();

  return axios({
    ...options,
    data
  });
};

dataProvider.update = (resource: string, tab: string, data: string | Object): Promise<any> => {
  console.group(`%cAPI REQUEST UPDATE ${resource}/${tab}`, 'font-size: medium; color: #DAF7A6');
  const options = getOptions(resource, tab, 'PUT');
  console.log({ ...options, data });
  console.groupEnd();
  return axios({
    ...options,
    data
  })
    .then(({ data: newData }) => ({ data: newData }))
    .catch(error => {
      dispatchCustomNotificationEvent(error);
    });
};

dataProvider.updateMany = (resource: string, params: Object) => Promise;

dataProvider.delete = (resource: string, tab: string, id?: string | null, data: Object | Array<*>) => {
  console.group(`%cAPI REQUEST DELETE ${resource}/${tab}`, 'font-size: medium; color: #808000');
  const options: Object = getOptions(resource, id ? `${tab}/${id}` : tab, 'DELETE');
  options.data = data;
  console.log(options);
  console.groupEnd();
  // $FlowFixMe
  return axios(options);
};

dataProvider.deleteMany = (resource: string, params: Object) => Promise;

dataProvider.post = (resource: string, tab: string, id?: string | null, data: Object | Array<*>) => {
  console.group(`%cAPI REQUEST POST ${resource}/${tab}`, 'font-size: medium; color: #808000');
  const options: Object = getOptions(resource, id ? `${tab}/${id}` : tab, 'POST');
  options.data = data;
  console.log(options);
  console.groupEnd();
  // $FlowFixMe
  return axios(options);
};

dataProvider.postUrl = (url: string, data: Object, filetype: string) => {
  console.group(`%cAPI REQUEST POST ${url}`, 'font-size: medium; color: #808000');
  console.log('url data: ', url, data);
  return axios({
    url,
    method: 'POST',
    headers: {
      'Content-Type': filetype
    },
    data
  })
    .then(({ data: newData }) => ({ data: newData }))
    .catch(error => {
      dispatchCustomNotificationEvent(error);
    });
};

/**
 *
 * @param {string} url to which the file is uploaded
 * @param {object} data of the request body
 * @param {string} contentType content type of the request body
 */
dataProvider.postUrl_propagatesError = (url, data, contentType) => {
  console.group(`%cAPI REQUEST POST ${url}`, 'font-size: medium; color: #808000');
  console.log('url data: ', url, data);
  return axios({
    url,
    method: 'POST',
    headers: {
      'Content-Type': contentType
    },
    data
  });
};

export default dataProvider;