import axios from 'axios';
import apiClient from '../apiClient/apiClient';
import { getStorage } from '../storage';
import { dataParams } from 'helpers';

const env = process.env.REACT_APP_BUILD_ENV;
const base = process.env.REACT_APP_BASE_URL;
const apiGWID = {
  partnerProfile: process.env.REACT_APP_BASE_URL_19,
  partners: process.env.REACT_APP_BASE_URL_19,
  darkWebLicense: process.env.REACT_APP_BASE_URL_9,
  darkWebPurchase: process.env.REACT_APP_BASE_URL_20,
  taxExempt: process.env.REACT_APP_BASE_URL_14,
  newsletters: process.env.REACT_APP_BASE_URL_1,
  microTrainings: process.env.REACT_APP_BASE_URL_1,
  policies: process.env.REACT_APP_BASE_URL_19,
};

const baseURL = (resource) => `https://${apiGWID[resource]}.${base}/${env}/`;

const admin = {};
const partners = {};
const partnersTaxExempt ={};
const newsletters = {};
const microTrainings = {};
const policies = {};
const clientNotes = {};
const partnerNotes = {};

const requestOptions = (resource, url, params, data) => {
  const base = baseURL(resource);
  return {
    url: `${base}${url}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: getStorage('idToken', true)
    },
    params,
    data,
  };
};

// partners apis
partners.getList = (params) => {
  return apiClient.get(requestOptions('partners', 'partnerslist', dataParams(params)));
};

partners.delete = (ids) => {
  return apiClient.delete(requestOptions('partners', `partner/information/${ids[0]}`));
};

partners.information = (partnerId) => {
  return apiClient.get(requestOptions('partners', `partner/information/${partnerId}`));
};

partners.update = (partnerId, record) => {
  return apiClient.put(requestOptions('partners', `partner/information/${partnerId}`, {}, record));
};

partners.create = (record) => {
  return apiClient.post(requestOptions('partners', `partner/addPartner`, {}, record));
};

partners.getSimilarPartners = (name) => {
  return apiClient.get(requestOptions('partners', `partner/checkSamePartners`, { name } ));
};

partners.getDarkWebLicense = (partnerId) => {
  return apiClient.get(requestOptions('darkWebLicense', `admin/darkWebLicenseData/${partnerId}`));
};

partners.applyCoupon = (partnerId, record) => {
  return apiClient.post(requestOptions('darkWebLicense', `admin/darkWebLicenseData/${partnerId}`, {}, record));
};

partners.deleteCoupon = (partnerId, record) => {
  return apiClient.delete(requestOptions('darkWebLicense', `admin/darkWebLicenseData/${partnerId}`, {}, record));
};

partners.updateDWMLicense = (partnerId, record) => {
  return apiClient.post(requestOptions('darkWebPurchase', `admin/updateDWMLicense/${partnerId}`, {}, record));
};

partners.getTmsOptions = () => {
  return apiClient.get(requestOptions('partners', `partner/tmsinformation`));
};

partnersTaxExempt.getList = (params, partnerId) => {
  return apiClient.get(requestOptions('taxExempt', `admin/certificates/${partnerId}`));
};

partnersTaxExempt.getCertificate = (partnerId, certificateId) => {
  return apiClient.get(requestOptions('taxExempt', `admin/certificate/${partnerId}`, { certificate: certificateId }));
};

partners.getBilling = (partnerId) => {
  return apiClient.get(requestOptions('taxExempt', `admin/billing/${partnerId}`));
};

partners.updatePartnerProfile = (partnerId, fileName) => {
  return apiClient.put(requestOptions('partnerProfile', `partnerProfile/customProfile/${partnerId}`, {}, fileName));
};

partners.updatePartnerProfileServiceLogo = (partnerId, serviceLogo) => {
  return apiClient.put(requestOptions('partnerProfile', `partnerProfile/customProfile/${partnerId}`, {}, serviceLogo));
};

// newsletters apis
newsletters.getList = (params) => {
  const newParams = dataParams({ ...params, startAtZero: true });
  return apiClient.get(requestOptions('newsletters', 'myDashboard/snldocuments', newParams));
};

newsletters.delete = (ids) => {
  return apiClient.delete(requestOptions('newsletters', 'myDashboard/snldocuments', {}, { type: 'delete', ids }));
};

newsletters.information = (newsletterId) => {
  return apiClient.get(requestOptions('newsletters', `myDashboard/snldocuments/${newsletterId}`));
};

newsletters.update = (newsletterId, record) => {
  return apiClient.put(requestOptions('newsletters', `myDashboard/snldocuments/${newsletterId}`, {}, record));
};

newsletters.create = (record) => {
  return apiClient.post(requestOptions('newsletters', `myDashboard/snldocuments`, {}, record));
};

newsletters.actions = (type, id) => {
  return apiClient.put(requestOptions('newsletters', 'myDashboard/snldocuments', {}, { type, id }));
};

newsletters.configureEmail = (record) => {
  return apiClient.put(requestOptions('newsletters', `myDashboard/snldocuments`, {}, record));
};

// microTrainings apis
microTrainings.getList = (params) => {
  const newParams = dataParams({ ...params, startAtZero: true });
  return apiClient.get(requestOptions('microTrainings', 'myDashboard/adminMicroTrainings', newParams));
};

microTrainings.delete = (ids) => {
  return apiClient.delete(requestOptions('microTrainings', 'myDashboard/adminMicroTrainings', {}, { type: 'delete', ids }));
};

microTrainings.information = (newsletterId) => {
  return apiClient.get(requestOptions('microTrainings', `myDashboard/adminMicroTrainings/${newsletterId}`));
};

microTrainings.update = (newsletterId, record) => {
  return apiClient.put(requestOptions('microTrainings', `myDashboard/adminMicroTrainings/${newsletterId}`, {}, record));
};

microTrainings.create = (record) => {
  return apiClient.post(requestOptions('microTrainings', `myDashboard/adminMicroTrainings`, {}, record));
};

microTrainings.actions = (type, id) => {
  return apiClient.put(requestOptions('microTrainings', 'myDashboard/adminMicroTrainings', {}, { type, id }));
};

microTrainings.configureEmail = (record) => {
  return apiClient.put(requestOptions('microTrainings', `myDashboard/adminMicroTrainings`, {}, record));
};

policies.getList = (params) => {
  return apiClient.get(requestOptions('policies', 'policieslist', dataParams(params)));
};

// client admin tab
admin.getInsuranceCancellation = (clientId) => {
  return apiClient.get(requestOptions('darkWebPurchase', `admin/insuranceCancellation/${clientId}`));
};

admin.createInsuranceCancellation = (clientId, record) => {
  return apiClient.post(requestOptions('darkWebPurchase', `admin/insuranceCancellation/${clientId}`, {}, record));
};

admin.getDwba = (clientId) => {
  return apiClient.get(requestOptions('darkWebLicense', `admin/dwbaActivities/${clientId}`));
};

admin.clearDwba = (clientId, activity) => {
  return apiClient.post(requestOptions('darkWebLicense', `admin/dwbaActivities/${clientId}`, {}, { activity }));
};

clientNotes.getList = (params, id) => {
  const newparams = {...dataParams(params), partner: 0 };
  return apiClient.get(requestOptions('partners', `note/${id}`, newparams));
};

partnerNotes.getList = (params, id) => {
  const newparams = dataParams({ ...params, partner: 1 });
  return apiClient.get(requestOptions('partners', `note/${id}`, newparams));
}

admin.pressignedUploadFile = (resource, id, filename, type) => {
  return apiClient.post(
    requestOptions(resource, `myCompany/presignedUpload/${id}`, {}, { filename, type })
  );
};

admin.postUrl = (url, data, filetype) => {
  const requestOptions = {
    url,
    method: 'POST',
    headers: { 'Content-Type': filetype },
    data ,
  };
  
  return axios(requestOptions)
    .then(({ data: newData }) => ({ data: newData }))
    .catch((error) => console.log(error));
};

admin.partners = partners;
admin.partnersTaxExempt = partnersTaxExempt;
admin.newsletters = newsletters;
admin.microTrainings = microTrainings;
admin.policies = policies;
admin.clientNotes = clientNotes;
admin.partnerNotes = partnerNotes;

export default admin;