import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AccordionSummary, AccordionDetails } from '@material-ui/core';
import { ButtonCancel } from 'components';
import {
  ExpandMore as ExpandMoreIcon,
  ErrorOutline as ErrorOutlineIcon,
} from '@material-ui/icons';
import { SystemAccordion, } from '../../style';
import {
  ArrowRightIcon,
  Button,
  Grid,
  Paper,
  RadioGroup,
  RadioInput,
  SaveIcon,
  TextField,
  Typography,
} from '@trustsecurenow/components-library';

const OS_CHOICES = [
  { label: 'Windows', value: 'Windows' },
  { label: 'UNIX', value: 'UNIX' },
  { label: 'Linux', value: 'Linux' },
  { label: 'Mac OS', value: 'Mac OS' },
  { label: 'Other', value: 'Other' }
];

const LOCATION_CHOICES = [
  { value: 'Onsite', label: 'Onsite' },
  { value: 'Hosted by a 3rd party', label: 'Hosted by a 3rd party' },
  { value: 'Vendor Hosted', label: 'Vendor Hosted' },
  { value: 'Other', label: 'Other' }
];

const SYSTEMS = [
  {
    name: 'system_1',
    panel: 'panel1',
    number: 1
  },
  {
    name: 'system_2',
    panel: 'panel2',
    number: 2
  },
  {
    name: 'system_3',
    panel: 'panel3',
    number: 3
  },
  {
    name: 'system_4',
    panel: 'panel4',
    number: 4
  },
  {
    name: 'system_5',
    panel: 'panel5',
    number: 5
  }
];

const System = ({ state, setState, onSave, isHSN, handleClose, currentTabIndex, dirty }) => {
  const [expanded, setExpanded] = useState(false);

  const handleChangeState = event => {
    const { name, value } = event.target;
    const newState = { ...state, [name]: value };
    setState(newState);
  };

  const handleChangeAccordion = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const save = action => {
    onSave(action, currentTabIndex);
  };

  const handleNumberInputKeyDown = e => {
    if (/^[eE]$/.test(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <>
      {/* Information about data */}
      <SystemAccordion
        expanded={expanded === 'panelNetworkSystem'}
        onChange={handleChangeAccordion('panelNetworkSystem')}
      >
        {/* Title */}
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panelNetworkSystembh-content"
          id="panelNetworkSystembh-header"
        >
          <Typography variant='p' component='p' sx={{ flexShrink: 0, display: 'flex', alignItems: 'center' }} fontSize={16} lineHeight={1.5} my={2}>
            <ErrorOutlineIcon color="secondary" style={{ marginRight: 4 }} />
            Information about data
          </Typography>
        </AccordionSummary>

        {/* Body */}
        <AccordionDetails>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Paper fullWidth radius={2} pb={2}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant='p' component='p' fontSize={16} sx={{ m: 0 }}>
                      Please list any systems that contain {isHSN ? 'PHI' : 'PII'} or Sensitive Data. Keep in mind that{' '}
                      {isHSN ? 'PHI' : 'PII'} might be clients, customers, vendors, employees, etc. Systems may include:
                      Billing Systems, Customer Relationship Management, Order Management Systems, Website(s), Network
                      File Shares, Collaborative Systems (Microsoft SharePoint, Google Docs, etc.), Multi-function
                      copy/print/scanners that contain hard drives, Database Systems, Employee / Human Resource
                      Management Systems, Vendor Management Systems, Accounting and Financial Systems, etc.
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </AccordionDetails>
      </SystemAccordion>

      {/* Systems */}
      {SYSTEMS.map(item => {
        const { name, panel, number } = item;
        return (
          <SystemAccordion key={name} expanded={expanded === panel} onChange={handleChangeAccordion(panel)}>
            {/* Title */}
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={`${name}bh-content`}
              id={`${name}bh-header`}
            >
              <Typography variant='p' component='p' sx={{ flexShrink: 0, my: 2 }} fontSize={16} lineHeight={1.5}>
                System {number} {number === 1 && '(Main)'}
              </Typography>
            </AccordionSummary>

            {/* Body */}
            <AccordionDetails>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Paper fullWidth radius={2} pb={2}>
                    <Grid container>
                      {/* System Name */}
                      <Grid item pl={2} pr={2} pb={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                        <TextField
                          value={state[`${name}_name`]}
                          name={`${name}_name`}
                          label="System Name"
                          fullWidth
                          onChange={handleChangeState}
                        />
                      </Grid>

                      {/* Operating System */}
                      <Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container spacing={4} justifyContent="space-between">
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            {/* eslint-disable-next-line react/jsx-pascal-case */}
                            <Typography variant='h3' sx={{ padding: 2, margin: 0.1 }} fontSize={16}>
                              Operating System
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* Os */}
                      <Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                        <RadioGroup
                          sx={{ marginTop: 2, marginBottom: 1 }}
                          row
                          name={`${name}_os`}
                          value={state[`${name}_os`]}
                          onChange={handleChangeState}
                        >
                          {OS_CHOICES.map((choice) => (
                            <RadioInput
                              key={choice.value}
                              value={choice.value}
                              label={choice.label}
                              labelPlacement="end"
                              color="default"
                            />))}
                        </RadioGroup>
                      </Grid>

                      <Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container spacing={4} justifyContent="space-between">
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            {/* eslint-disable-next-line react/jsx-pascal-case */}
                            <Typography variant='h3' sx={{ padding: 2, margin: 0.1 }} fontSize={16}>
                              {isHSN
                                ? 'System Vendor and # of ePHI Records (estimate):'
                                : 'System Vendor and # of PII or sensitive data'}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* System Vendor */}
                      <Grid item pl={2} pr={2} pb={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                        <TextField
                          value={state[`${name}_vendor`]}
                          name={`${name}_vendor`}
                          label="System Vendor"
                          fullWidth
                          onChange={handleChangeState}
                        />
                      </Grid>

                      {/* ePHI or PII */}
                      <Grid item pl={2} pr={2} pb={2} xs={12} sm={12} md={4} lg={4} xl={4}>
                        <TextField
                          value={isHSN ? state[`${name}_ephi`] : state[`${name}_pii`]}
                          name={isHSN ? `${name}_ephi` : `${name}_pii`}
                          label={isHSN ? '# of ePHI or Sensitive Data' : '# of PII or Sensitive Data'}
                          fullWidth
                          onChange={handleChangeState}
                          type="number"
                          inputProps={{ min: 0, step: 1, maxLength: 7 }}
                          onKeyDown={handleNumberInputKeyDown}
                        />
                      </Grid>

                      {/* System Location */}
                      <Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container spacing={4} justifyContent="space-between">
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            {/* eslint-disable-next-line react/jsx-pascal-case */}
                            <Typography variant='h3' sx={{ padding: 2, margin: 0.1 }} fontSize={16}>
                              System Location
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* Location */}
                      <Grid item pl={2} pr={2} xs={12} sm={12} md={12} lg={12} xl={12}>
                        <RadioGroup
                          sx={{ marginTop: 2, marginBottom: 1 }}
                          row
                          name={`${name}_location`}
                          value={state[`${name}_location`]}
                          onChange={handleChangeState}
                        >
                          {LOCATION_CHOICES.map((choice) => (
                            <RadioInput
                              key={choice.value}
                              value={choice.value}
                              label={choice.label}
                              labelPlacement="end"
                              color="default"
                            />))}
                        </RadioGroup>
                      </Grid>

                      {/* System Details */}
                      <Grid item bt={1} xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Grid container spacing={4} justifyContent="space-between">
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            {/* eslint-disable-next-line react/jsx-pascal-case */}
                            <Typography variant='h3' sx={{ padding: 2, margin: 0.1 }} fontSize={16}>
                              System Details
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>

                      {/* Details */}
                      <Grid item pl={2} pr={2} xs={12} sm={12} md={6} lg={6} xl={6}>
                        <TextField
                          value={state[`${name}_details`]}
                          name={`${name}_details`}
                          placeholder="Please provide details of the system (how it is used, who uses it, etc.)"
                          fullWidth
                          rows={4}
                          multiline
                          onChange={handleChangeState}
                        />
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </AccordionDetails>
          </SystemAccordion>
        );
      })}

      {/* Action buttons */}
      <Grid item mt={3} xs={12} sm={12} md={12} lg={12} xl={12}>
        <Grid container spacing={4} display='flex' justifyContent="flex-end">
          <Grid m={2} display='flex' justifyContent="flex-end" item xs={6} sm={6} md={6} lg={6} xl={6}>
            <ButtonCancel variant="text" onClick={e => handleClose()}>
              Exit
            </ButtonCancel>
            <Button color="info" startIcon={<SaveIcon />} variant="contained" disableElevation onClick={save} disabled={!dirty}>
              Save
            </Button>
            <Button
              color="success"
              startIcon={<ArrowRightIcon />}
              variant="contained"
              disableElevation
              sx={{ marginLeft: '30px' }}
              onClick={() => save('next')}
            >
              Save and Next
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

System.propTypes = {
  state: PropTypes.oneOfType([null, PropTypes.object]).isRequired,
  setState: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  isHSN: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  currentTabIndex: PropTypes.number.isRequired
};

export default System;
