// @flow
import React, { memo, type Element, useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import LoadingStyled from 'components/types/Loading';
import PageError from 'components/common/PageError';
import { useLocation, useClientAccess } from 'hooks';
import { getStorage, dataProvider, decodeId, USER_ROLES } from 'helpers';
import { useNotify } from 'react-admin';
import { useHistory } from 'react-router-dom';
import type { clientsUsersTypes } from 'conf';
import { state } from 'conf';
import useBreakpoint from 'hooks/useBreakpoint';
import useQueryString from 'hooks/useQueryString';
import MoveUsersModal from './moveUsers/MoveUsersModal';
import SuccessCircleIcon from 'components/icons/Enable';
import CloseCircleIcon from 'components/icons/CloseCircle';
import Info from 'components/icons/Info';
import CustomTooltip from 'components/common/CustomTooltip';
import ClientsUsersToolbar from './ClientsUsersToolbar';
import TableListModal from '../../../components/tablelist/TableListModal';
import UsersListToolbarMobile from './mobile/UsersListToolbarMobile';
import dwAPI from 'helpers/apis/darkWeb';
import TopToolbar from './TopToolbar';
import clientsApi from 'helpers/apis/clients';
import { makeStyles, Typography } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  tooltipContainer: {
    textAlign: 'left'
  },
  text: {
    color: 'var(--colorDefault)',
    fontSize: 13
  },
  icon: {
    verticalAlign: 'middle'
  },
  hover: {
    '&:hover': {
      color: 'var(--colorSystemInfo)'
    }
  }
}));

type ClientsEditUsersTypes = {
  record: clientsUsersTypes,
  onChangeData: Function,
  id: string,
  dispatch: {
    update: Function
  }
};

let timerId = 0;

const ClientsEditUsers = ({
  record: {
    enable_mfa_for_users: enableMFA,
    enforce_strong_password_policy: strongPass,
    enable_tags: enableTags,
    enable_filters: enableFilters,
    enable_actions: enableActions
  },
  onChangeData,
  id,
  dispatch
}: ClientsEditUsersTypes): Element<*> => {
  const classes = useStyles();
  const history = useHistory();
  const { app: appLoc, tab: tabLoc, item: itemLoc } = useLocation('clientId');
  const app = 'clients';
  const tab = 'usersList';
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState({
    exists: false,
    msg: ''
  });
  const notify = useNotify();
  const mobileView = useBreakpoint('sm');
  const [disabeldActions, setDisabeldActions] = useState(['reset_deleted', 'permanent_delete']);
  const [isSelectedDeletedFilter, setIsSelectedDeletedFilter] = useState(false);
  const [modalAction, setModalAction] = useState(null);
  const [refetch, setRefetch] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const productName = useSelector(state => state?.bsn?.user?.profile?.product_name);
  const tableRowSelectedList = useSelector(state => state?.bsn?.system?.tableRowSelectedList);
  const dw_remediation = useSelector(s => s?.bsn?.user?.access?.features?.dw_remediation);
  const userRole = useSelector(({ bsn }) => bsn?.user?.profile?.user_role);
  const isBPPClient = productName === 'Breach Prevention Platform (BPP)';
  const { user_role } = useSelector(state => state?.bsn?.user?.profile ?? {});
  const clientAccess = useClientAccess({ id: itemLoc });
  const [settings, setSettings] = React.useState({});
  const {
    tables: { clients }
  } = state;

  const isMaskMode = Boolean(getStorage('maskMode', true));

  const eTag = data ? data?.enable_tags : enableTags;
  const eFilters = data ? data?.enable_filters : enableFilters;
  const eActions = React.useMemo(() => {
    return {
      ...(data?.enable_actions ?? enableActions),
      permanent_delete: isMaskMode,
      partner_welcome_message: isMaskMode,
      move_users: isMaskMode
    };
    // isMaskMode should always be none reference type
  }, [data?.enable_actions, enableActions, isMaskMode]);

  const hideColumns = data ? Object.keys(data?.hide_columns).filter(key => data?.hide_columns[key]) : [];
  const queryString = useQueryString();
  const prefilledSearch = JSON.parse(queryString.get('filter'))?.user || '';

  const onRemovePreSeach = () => {
    queryString.delete('filter');
    history.replace({
      search: queryString.toString()
    });
  };

  const tableOptions = {
    refetchOnWindowFocus: false,
    staleTime: Infinity
  };

  const showTooltipCreatedDate = [
    USER_ROLES.MANGER,
    USER_ROLES.MANAGER_ADMIN,
    USER_ROLES.PARTNER_ADMINISTRATOR,
    USER_ROLES.ADMINISTRATOR
  ].includes(user_role);

  //For admin users and mask mode enabled only
  const showTooltipID = isMaskMode && [USER_ROLES.ADMINISTRATOR].includes(user_role);

  const tableSettings = useMemo(() => {
    const tableState = isBPPClient ? clients.usersBPPList : clients.usersList;

    const cells = tableState.cells.map(cell => {
      if (cell.id === 'email' && showTooltipCreatedDate) {
        return {
          ...cell,
          label: (
            <React.Fragment>
              <span>Email</span>
              <CustomTooltip arrow light title={`Created Date ${showTooltipID ? '/ User ID' : ''}`} placement="right">
                <Info color="colorDefault" className={`${classes.icon} ${classes.hover}`} ml={2} size={1} />
              </CustomTooltip>
            </React.Fragment>
          )
        };
      }

      return cell;
    });

    return {
      ...tableState,
      cells
    };
  }, [isBPPClient, clients, isMaskMode]);

  useEffect(() => {
    setLoading(true);
    const pathAfterTab = appLoc === 'clients' ? `users-mfa_and_strong_password_status/${itemLoc}` : `users/${itemLoc}`;
    dataProvider
      .getOne(appLoc, pathAfterTab, {})
      .then(response => {
        setData({ ...response.data });
        setLoading(false);
        setError({
          exists: false,
          msg: ''
        });
      })
      .catch(err => {
        setLoading(false);
        setError({
          exists: true,
          msg: err.message || 'something went wrong!'
        });
      });
  }, []);

  React.useEffect(() => {
    if (!itemLoc) {
      return;
    }
    dwAPI
      .getSettings(itemLoc)
      .then(res => {
        setSettings(res.data);
      })
      .catch(err => {
        notify(err?.response?.data?.description || err?.response?.data?.message || 'Something Went Wrong');
      });
  }, [itemLoc, notify]);

  useEffect(() => {
    const disabelWelcomeMessage =
      isSelectedDeletedFilter || tableRowSelectedList.some(el => !el.active || el.removes_bounce_email);
    const isEnableBouncedEmailAction = tableRowSelectedList.some(user => user.removes_bounce_email);

    setDisabeldActions(prevState =>
      isEnableBouncedEmailAction
        ? prevState.filter(item => item !== 'clear_bounce_email')
        : [...prevState, 'clear_bounce_email']
    );

    setDisabeldActions(prevState =>
      disabelWelcomeMessage ? [...prevState, 'welcome_message'] : prevState.filter(item => item !== 'welcome_message')
    );

    const disablePaWelcomeMessage =
      isSelectedDeletedFilter ||
      tableRowSelectedList.length !== 1 ||
      tableRowSelectedList.some(el => !el.active || el.removes_bounce_email || el.group_role.label !== 'PA');

    if (disablePaWelcomeMessage) setDisabeldActions(prevState => [...prevState, 'partner_welcome_message']);
    else setDisabeldActions(prevState => prevState.filter(item => item !== 'partner_welcome_message'));
  }, [isSelectedDeletedFilter, tableRowSelectedList]);

  const refreshList = () => {
    setRefresh(true);

    clearTimeout(timerId);
    timerId = setTimeout(() => {
      setRefresh(false);
    }, 300);
  };

  useEffect(() => {
    if (modalAction?.refetch || refetch) refreshList();
  }, [modalAction?.refetch, refetch]);

  if (loading) return <LoadingStyled centerAlign />;
  if (error.exists)
    return (
      <PageError
        title="Oops!"
        subtitile="There was a problem with the page?"
        description={error.msg}
        linkText="Contact Us"
        linkTo="/user/contactUs"
      />
    );

  return (
    <>
      <TableListModal
        tab={tab}
        resetTable={tab}
        refresh={refresh}
        id={id}
        app={app}
        topToolbar={userRole !== 'Manager' && userRole !== 'User' && userRole !== 'Pending' ? <TopToolbar /> : null}
        toolbar={
          <ClientsUsersToolbar
            setRefetch={setRefetch}
            enableTags={eTag}
            enableFilters={eFilters}
            enableActions={eActions} // enableActions is an effect Dependency
            setDisabeldActions={setDisabeldActions} // setDisabeldActions should be a dispatch/useCallback function
            setIsSelectedDeletedFilter={setIsSelectedDeletedFilter}
            transformBeforeSubmit={transformUserPhoneNumberData}
          />
        }
        toolbarMobile={
          mobileView ? (
            <UsersListToolbarMobile
              enableActions={eActions}
              setDisabeldActions={setDisabeldActions}
              setIsSelectedDeletedFilter={setIsSelectedDeletedFilter}
              transformBeforeSubmit={transformUserPhoneNumberData}
            />
          ) : null
        }
        enableFilters={eFilters}
        tableOptions={tableOptions}
        tableActions={eActions}
        disabeldActions={disabeldActions}
        refetchOnUpdate={refreshList}
        tableSettings={tableSettings}
        hideColumns={hideColumns}
        transformBeforeSubmit={transformUserPhoneNumberData}
        prefilledSearch={prefilledSearch}
        onRemovePreSeach={onRemovePreSeach}
        setModalAction={setModalAction}
        hideColumnsCallback={obj => {
          if (!settings.dw_remediation) {
            return [...(obj?.hideColumns ?? []), 'dwr_remediated_count', 'dwr_training_completed'];
          }
          return obj?.hideColumns ?? [];
        }}
        customCells={{
          dwr_remediated_count: ({ row }) => {
            const toolTipText = (row?.active || false) === false ? 'Not an active user' : 'User does not have breaches';
            const dwr_remediated_count = row?.dwr_remediated_count;

            if (dwr_remediated_count === undefined || dwr_remediated_count === null || dwr_remediated_count == -1) {
              return (
                <CustomTooltip title={toolTipText} arrow light placement="top">
                  <>N/A</>
                </CustomTooltip>
              );
            }
            return <>{dwr_remediated_count}</>;
          },
          dwr_training_completed: ({ row }) => {
            const toolTipText = (row?.active || false) === false ? 'Not an active user' : 'User does not have breaches';
            const dwr_training_completed = row?.dwr_training_completed;

            if (
              dwr_training_completed === undefined ||
              dwr_training_completed === null ||
              dwr_training_completed === -1
            ) {
              return (
                <CustomTooltip title={toolTipText} arrow light placement="top">
                  <>N/A</>
                </CustomTooltip>
              );
            }
            return dwr_training_completed === 1 ? (
              <SuccessCircleIcon color="colorSystemSuccess" strokeColor="colorSystemSuccess" ml={1.1} mr={1.1} />
            ) : (
              <CloseCircleIcon color="colorSystemDanger" ml={1.1} mr={1.1} />
            );
          },
          last_login: ({ row }) => {
            const { last_login } = row;
            if (last_login === null) return <>N/A</>;
            const color =
              // eslint-disable-next-line no-nested-ternary
              last_login >= 90
                ? 'var(--colorSystemDanger)'
                : last_login >= 30
                ? 'var(--colorSystemWarning)'
                : 'var(--colorDefault)';
            return (
              <Typography variant="body2" style={{ fontSize: 14, color }}>
                {last_login}
              </Typography>
            );
          },
          welcome_email_received: ({ row }) => {
            const { welcome_email_received } = row;
            const title = welcome_email_received ? 'Welcome Email Sent' : 'Welcome Email Not Sent';
            return (
              <CustomTooltip title={title} arrow light placement="top">
                {welcome_email_received ? (
                  <SuccessCircleIcon color="colorSystemSuccess" strokeColor="colorSystemSuccess" />
                ) : (
                  <CloseCircleIcon color="colorSystemDanger" />
                )}
              </CustomTooltip>
            );
          },
          email: ({ row }) => {
            const tooltip = showTooltipCreatedDate ? (
              <div className={classes.tooltipContainer}>
                <Typography className={classes.text}>Created Date: {row.created_date}</Typography>

                {showTooltipID ? <Typography className={classes.text}>User ID: {decodeId(row.id)}</Typography> : <></>}
              </div>
            ) : (
              ''
            );

            return (
              <CustomTooltip title={tooltip} arrow light placement="top">
                <span className={tooltip ? classes.hover : ''}>{row.email}</span>
              </CustomTooltip>
            );
          }
        }}
      />
      <MoveUsersModal
        open={modalAction?.open}
        onClose={() => setModalAction(old => ({ open: false, refetch: false }))}
        onRefetch={() => setModalAction(old => ({ ...old, refetch: true }))}
        {...modalAction}
      />
    </>
  );
};

export function transformUserPhoneNumberData(obj) {
  const onlyCountryCode = /^(\+?(\d{1,3}|\d{1,4}))$/;
  const [cell_number, phone_number, mobile_number] = [
    obj.cell_number ?? '',
    obj.phone_number ?? '',
    obj.mobile_number ?? ''
  ].map(v => v.replaceAll(/[\s()-]/g, ''));

  if (onlyCountryCode.test(obj.cell_number)) {
    obj.cell_number = '';
  }

  if (onlyCountryCode.test(obj.phone_number)) {
    obj.phone_number = '';
  }

  if (onlyCountryCode.test(obj.mobile_number)) {
    obj.mobile_number = '';
  }

  return obj;
}

// async function fetch(item: string, record, setRecord) {
//   const app = 'clients';
//   const tab = 'users';
//   try {
//     const queryName = dataQueryName({ app, tab, item });
//     const query = await queryClient.fetchQuery(queryName, async () => {
//       const { data } = await dataFetch({ app, tab, item });
//       return data;
//     });
//     if (isObjectEmpty(record)) setRecord(query);
//   } catch (error) {
//     // eslint-disable-next-line no-console
//     console.log(error);
//   }
// }

export default memo<ClientsEditUsersTypes>(ClientsEditUsers);
