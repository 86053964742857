import {
  AlertDialog,
  Autocomplete,
  Box,
  Button,
  CheckIcon,
  Divider,
  IconButton,
  InfoIcon,
  Link,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
  enqueueAlertSnackbar
} from '@trustsecurenow/components-library';
import clientsAPI from 'helpers/apis/clients';
import { useDebounce } from 'hooks';
import React, { memo, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';

const ClientsCreate = () => {
  const history = useHistory();
  const [isSaving, setIsSaving] = useState(false);
  const [isOpenConfirmationModal, setIsOpenConfirmationModal] = useState(false);
  const [selectedIndustry, setSelectedIndustry] = useState('');
  const [selectedTMSClient, setSelectedTMSClient] = useState('');
  const [searchValueTMS, setSearchValueTMS] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [debounced] = useDebounce({ value: searchValueTMS, delay: 500 });

  const { partner_id } = useSelector(s => s?.bsn?.user?.profile ?? {});
  const accessApps = useSelector(state => state?.bsn?.user?.access?.apps);
  const hasAccess = accessApps?.hasOwnProperty('clients');

  // switch partner id in case of mask mode
  const maskMode = localStorage.getItem('maskMode');
  const maskPartnerId = localStorage.getItem('maskPartnerId');
  const partnerId = maskMode ? maskPartnerId : partner_id;

  const { data: industriesList, isFetching: isFetchingIndustries } = useQuery(
    ['industries-picklist', partnerId, maskMode, maskPartnerId],
    () =>
      clientsAPI.getPickList({
        client_id: partnerId,
        types: 'industries',
        ...(maskMode && { masked_partner_id: maskPartnerId })
      }),
    {
      enabled: !!partnerId,
      select: res => res?.data?.industries?.map(d => ({ label: d.name, value: d.id })),
      refetchOnWindowFocus: false,
      onerror: () => {
        enqueueAlertSnackbar('Failed to get industries list', { props: { severity: 'error' } });
      }
    }
  );

  const { data: TMS, isFetching: isFetchingTMSClients } = useQuery(
    ['TMSClients-picklist', partnerId, maskMode, maskPartnerId, debounced.value],
    () =>
      clientsAPI.getPickList({
        client_id: partnerId,
        types: 'tms_clients',
        ...(maskMode && { masked_partner_id: maskPartnerId }),
        ...(debounced.value && { filters: { tms: debounced.value } })
      }),
    {
      enabled: !!partnerId,
      select: res => {
        const { tms_clients = [], tms_name = null } = res.data;
        return {
          clientsList: tms_clients.map(d => ({
            label: d.AccountName ?? d.name,
            value: d.id ?? d.identifier
          })),
          name: tms_name
        };
      },
      refetchOnWindowFocus: false,
      onerror: () => {
        enqueueAlertSnackbar('Failed to get TMS clients list', { props: { severity: 'error' } });
      }
    }
  );

  const handleSave = proceed => {
    if (!companyName || !selectedIndustry) {
      const message = !companyName ? 'Please enter a company name' : 'Please select industry';
      enqueueAlertSnackbar(message, { props: { severity: 'warning' } });
      return;
    }

    setIsSaving(true);

    clientsAPI
      .addClient(partnerId, {
        name: companyName,
        industry_id: selectedIndustry,
        proceed: proceed ?? false,
        ...(selectedTMSClient ? { tms: { tms_client_id: selectedTMSClient, tms_name: TMS?.name } } : {})
      })
      .then(res => {
        enqueueAlertSnackbar('Client has been created', { props: { severity: 'success' } });
        history.push(`/clients`);
      })
      .catch(err => {
        if (err.response.status === 400) {
          // already exist
          setIsOpenConfirmationModal(true);
        } else {
          enqueueAlertSnackbar(err.response.data.status, { props: { severity: 'error' } });
        }
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  if (accessApps && !hasAccess) history.push('/myDashboard/dashboard');

  return (
    <>
      <Paper component={Box} mt={2}>
        <Stack>
          <Stack p={3}>
            <Typography variant="h3">Create New Client</Typography>
          </Stack>
          <Divider />
          <Stack flexDirection="row" p={3} gap={2} flexWrap="wrap">
            {TMS?.name && (
              <Stack flexDirection="row" gap={1} alignItems="center">
                <Autocomplete
                  sx={{ width: 300 }}
                  options={TMS?.clientsList || []}
                  getOptionLabel={option => option.label}
                  fullWidth
                  loadingText="loading"
                  loading={isFetchingTMSClients}
                  onChange={(_ev, item) => {
                    setSelectedTMSClient(item?.value);
                    setCompanyName(name => item?.label ?? name);
                  }}
                  onClose={() => {
                    if (!selectedTMSClient) setSearchValueTMS('');
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      onChange={e => setSearchValueTMS(e.target.value)}
                      fullWidth
                      label="Select Client from TMS"
                      variant="outlined"
                    />
                  )}
                />
                <Tooltip
                  title={
                    <div>
                      Please note, this field is not required to <br />
                      create a new client. All clients that have <br />
                      already mapped will not be in the list.
                    </div>
                  }
                  placement="top"
                  variant="dark"
                >
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </Stack>
            )}
            <TextField
              label="Company Name"
              variant="outlined"
              value={companyName}
              onChange={e => setCompanyName(e.target.value)}
              sx={{ width: 300 }}
            />
            <Stack flexDirection="row" gap={1} alignItems="center">
              <TextField
                id="selectIdentityProviders"
                name="selectIdentityProviders"
                label="Industry"
                select
                value={selectedIndustry}
                onChange={e => {
                  setSelectedIndustry(e.target.value);
                }}
                sx={{ width: 300 }}
              >
                {industriesList?.map(({ value, label }) => (
                  <MenuItem key={value} value={value}>
                    {label}
                  </MenuItem>
                ))}
              </TextField>

              <Tooltip
                title={
                  <div>
                    This information will help us create more <br />
                    industry specific content in the future
                  </div>
                }
                placement="top"
                variant="dark"
              >
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
          <Divider />
          <Stack p={3} flexDirection="row" justifyContent="flex-end">
            <Stack flexDirection="row" alignItems="center" gap={2}>
              <Link variant="link2" component={RouterLink} to="/clients">
                Cancel
              </Link>
              <Button
                onClick={() => handleSave(false)}
                color="success"
                loading={isSaving}
                disabled={isSaving || isFetchingTMSClients}
                startIcon={<CheckIcon />}
              >
                Create
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Paper>
      <AlertDialog
        open={isOpenConfirmationModal}
        dialogTitle="Create new client?"
        message={
          <div>
            A client with a similiar name already exists in your account. <br />
            Would you like to create this client anyway?
          </div>
        }
        SubmitButtonProps={{
          color: 'success',
          children: 'Yes, Create new client',
          onClick: () => handleSave(true),
          loading: isSaving
        }}
        onClose={() => setIsOpenConfirmationModal(false)}
      />
    </>
  );
};

export default memo(ClientsCreate);
