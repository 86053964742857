/* eslint-disable no-shadow */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  ArrowRightIcon,
  Avatar,
  CloseIcon,
  Dialog,
  DialogContent,
  HappyIcon,
  IconButton,
  QuizViewer,
  Stack,
  Typography,
  enqueueAlertSnackbar
} from '@trustsecurenow/components-library';
import { Loading } from 'react-admin';
import * as sra from 'helpers/apis/services/sra';
import * as quizManagementSystem from 'helpers/apis/services/quizManagementSystem';
import quiz from 'helpers/apis/quiz';
import { DeleteConfirmationModal } from 'components/modal';
import axios from 'axios';
import { alpha } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import QuestionsInfo from './SRAQuestionInfo';

const splitFilenameExtension = filename => {
  const lastIndexOfDot = filename.lastIndexOf('.');
  return {
    name: filename.substring(0, lastIndexOfDot),
    extension: filename.substring(lastIndexOfDot + 1, filename.length)
  };
};

const generateCategories = revision => {
  return revision.categories.reduce((sections, item) => {
    const isSectionFound = sections.some(({ id }) => id === item.quiz_section_id);
    const questions = revision.questions.filter(question => question.question_category_id === item.quiz_category_id);
    const category = {
      id: item.quiz_category_id,
      name: item.quiz_category_name,
      questionsCount: questions.length,
      description: ''
    };

    if (isSectionFound) {
      return sections.map(section => {
        if (section.id === item.quiz_section_id) return { ...section, categories: [...section.categories, category] };
        return section;
      });
    }

    const section = {
      id: item.quiz_section_id,
      name: item.quiz_section_name,
      categories: [category]
    };
    return [...sections, section];
  }, []);
};

const SRAQuestionsQuiz = ({ Container, clientId, onSubmit, quizRevisionId = null }) => {
  const history = useHistory();

  const isEditMode = quizRevisionId !== null;
  const [isNewQuiz, setIsNewQuiz] = useState();
  const [isloading, setIsLoading] = useState(true);
  const [revision, setRevision] = useState(null);
  const [isloadingQuestion, setIsloadingQuestion] = useState(true);
  const [selectedQuestionId, setSelectedQuestionId] = useState('');
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const [deletedAttachmentId, setDeletedAttachmentId] = useState('');
  const [cancelTokenSource, setCancelTokenSource] = useState(null);
  const [files, setFiles] = useState([]);
  const [isAtatchmentDialogOpen, setIsAttachmentDialogOpen] = useState(false);

  const steps = useMemo(() => {
    if (!revision?.questions) return [];
    return revision.questions.map(({ question_id, is_answered }) => ({ id: question_id, isAnswered: is_answered }));
  }, [revision]);

  const isAllAnswered = useMemo(() => {
    if (!revision?.questions) return false;
    return revision.questions.every(({ is_answered }) => is_answered);
  }, [revision]);

  const answers = useMemo(() => {
    if (!currentQuestion?.answers) return [];
    return currentQuestion.answers.map(({ answer, answer_id }) => ({ id: answer_id, title: answer }));
  }, [currentQuestion]);

  const attachments = useMemo(() => {
    if (!currentQuestion || !currentQuestion.attachments.enabled) return [];
    return files
      .filter(file => file.questionId === selectedQuestionId)
      .concat(
        currentQuestion.attachments.files.map(attachment => {
          const { extension, name } = splitFilenameExtension(attachment.display_name);
          return {
            id: attachment.attachment_id,
            editName: false,
            editDescription: false,
            isUploaded: true,
            isUploading: false,
            isDeleting: attachment.is_deleting,
            progress: 100,
            name,
            extension,
            description: attachment.description || '',
            link: attachment.file_url
          };
        })
      );
  }, [currentQuestion, files, selectedQuestionId]);

  const editQuizRevision = useCallback(() => quizManagementSystem.resumeQuizRevision(quizRevisionId), [quizRevisionId]);

  const getQuizRevision = useCallback(async () => {
    const { data: sraQuiz } = await sra.getSRAQuizId(clientId);
    const { status, data: state } = await quizManagementSystem.getQuizUserState(sraQuiz.quiz_id, clientId);
    return status === 204
      ? quizManagementSystem.createQuizRevision(sraQuiz.quiz_id, clientId)
      : quizManagementSystem.resumeQuizRevision(state.info.revision_id);
  }, [clientId]);

  const fetchQuiz = useCallback(async () => {
    const { data: revision } = isEditMode ? await editQuizRevision() : await getQuizRevision();

    setRevision({ ...revision, categories: generateCategories(revision) });
    const allQuestionsAnswered = revision.questions.every(({ is_answered }) => is_answered);
    setIsNewQuiz(!allQuestionsAnswered);

    const { data: question } = await quizManagementSystem.getCurrentQuestion(revision.revision_id);
    setSelectedQuestionId(question.question.id);
    setCurrentQuestion(question);

    setIsloadingQuestion(false);
    setIsLoading(false);
  }, [editQuizRevision, getQuizRevision, isEditMode]);

  useEffect(() => {
    fetchQuiz();
  }, [fetchQuiz]);

  useEffect(() => {
    const question = revision?.questions?.find(({ question_id }) => question_id === selectedQuestionId);
    if (!question) return;
    setSelectedCategoryId(question.question_category_id);
  }, [selectedQuestionId, revision]);

  useEffect(() => {
    const handleOnBeforeUnload = ev => {
      if (files.length) {
        ev.preventDefault();
      }
    };
    window.addEventListener('beforeunload', handleOnBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleOnBeforeUnload);
    };
  }, [files.length]);

  useEffect(() => {
    const unblock = history.block(location => {
      if (location.pathname !== history.location.pathname && !isloading) {
        files.forEach(async file => {
          if (file.isUploading) return;
          const filename = `${file.currentName}.${file.extension}`;
          const {
            data: { s3_filename, url, fields }
          } = await quizManagementSystem.getAttachmentUploadPost(revision.revision_id, filename);

          await quiz.uploadAttachment(url, fields, file.file);

          quizManagementSystem.saveAttachment(revision.revision_id, selectedQuestionId, s3_filename, filename, file.currentDescription);
        });
      }
      return true;
    });

    return () => {
      unblock();
    };
  }, [files, history, isloading, revision, selectedQuestionId]);

  const cancelAxiosRequest = () => {
    if (!cancelTokenSource) return;
    cancelTokenSource.cancel('cancel');
  };

  const getCancelToken = () => {
    const source = axios.CancelToken.source();
    setCancelTokenSource(source);
    return source.token;
  };

  const uploadAllAttachments = () => {
    files.forEach(file => {
      handleUploadAttachment(file.id);
    });
  };

  const handleChangeCategory = async id => {
    const previousQuestionId = selectedQuestionId;
    try {
      const firstQuestion = revision.questions.find(({ question_category_id }) => question_category_id === id);
      if (!firstQuestion) return;
      uploadAllAttachments();
      setIsloadingQuestion(true);
      setSelectedQuestionId(firstQuestion.question_id);
      const { data: question } = await quizManagementSystem.setCurrentQuestion(revision.revision_id, firstQuestion.question_id);
      setCurrentQuestion(question);
    } catch {
      setSelectedQuestionId(previousQuestionId);
      enqueueAlertSnackbar('Failed to navigate to category.', { props: { severity: 'error' } });
    } finally {
      setIsloadingQuestion(false);
    }
  };

  const handleAnswer = async id => {
    try {
      cancelAxiosRequest();
      const token = getCancelToken();
      const isAnswered = Boolean(currentQuestion.user_answer_id);
      if (!isAnswered) {
        setRevision(prevState => ({
          ...prevState,
          questions: prevState.questions.map(question =>
            question.question_id === selectedQuestionId ? { ...question, is_answered: true } : question
          )
        }));
      }
      setCurrentQuestion(prevState => ({ ...prevState, user_answer_id: id }));

      await quizManagementSystem.answerQuestion(revision.revision_id, selectedQuestionId, id, token);
    } catch (e) {
      if (e.message === 'cancel') return;
      enqueueAlertSnackbar('Failed to answer question.', { props: { severity: 'error' } });
    }
  };

  const handlePrevious = async () => {
    const previousQuestionId = selectedQuestionId;
    try {
      if (currentQuestion.question.number <= 1) return;
      setIsloadingQuestion(true);
      const { question_id } = revision.questions[currentQuestion.question.number - 2];
      setSelectedQuestionId(question_id);
      uploadAllAttachments();
      const { data: question } = await quizManagementSystem.setCurrentQuestion(revision.revision_id, question_id);
      setCurrentQuestion(question);
    } catch {
      setSelectedQuestionId(previousQuestionId);
      enqueueAlertSnackbar('Failed to navigate to question', { props: { severity: 'error' } });
    } finally {
      setIsloadingQuestion(false);
    }
  };

  const handleNext = async () => {
    uploadAllAttachments();
    if (currentQuestion.question.is_last) {
      handleSubmit();
      return;
    }

    const previousQuestionId = selectedQuestionId;
    try {
      setIsloadingQuestion(true);
      const { question_id } = revision.questions[currentQuestion.question.number];
      setSelectedQuestionId(question_id);
      await quizManagementSystem.nextQuestion(revision.revision_id, question_id);
      const { data: question } = await quizManagementSystem.getCurrentQuestion(revision.revision_id, question_id);
      setCurrentQuestion(question);
    } catch {
      setSelectedQuestionId(previousQuestionId);
      enqueueAlertSnackbar('Failed to navigate to question', { props: { severity: 'error' } });
    } finally {
      setIsloadingQuestion(false);
    }
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await quizManagementSystem.endSRAQuiz(revision.revision_id);
      enqueueAlertSnackbar('Saved Successfully', { props: { severity: 'success' } });
      onSubmit();
    } catch {
      enqueueAlertSnackbar('Failed to save quiz.', { props: { severity: 'error' } });
      setIsLoading(false);
    }
  };

  const handleNavigateToQuestion = async id => {
    if (id === selectedQuestionId) return;

    const previousQuestionId = selectedQuestionId;
    try {
      setIsloadingQuestion(true);
      setSelectedQuestionId(id);
      uploadAllAttachments();
      const { data: question } = await quizManagementSystem.setCurrentQuestion(revision.revision_id, id);
      setCurrentQuestion(question);
    } catch {
      setSelectedQuestionId(previousQuestionId);
      enqueueAlertSnackbar('Failed to navigate to question', { props: { severity: 'error' } });
    } finally {
      setIsloadingQuestion(false);
    }
  };

  const handleDropFiles = files => {
    const droppedFiles = files.map(file => {
      const { name, extension } = splitFilenameExtension(file.name);
      return {
        id: crypto.randomUUID(),
        name,
        currentName: name,
        currentDescription: '',
        description: '',
        extension,
        editName: false,
        editDescription: false,
        isUploaded: false,
        isUploading: false,
        isDeleting: false,
        progress: 0,
        file,
        questionId: selectedQuestionId
      };
    });
    setFiles(prevState => droppedFiles.concat(prevState));
  };

  const handleDeleteAttachment = async () => {
    try {
      const deletedAttachment = attachments.find(({ id }) => id === deletedAttachmentId);
      if (!deletedAttachment) return;
      setDeletedAttachmentId('');
      if (deletedAttachment.isUploaded) {
        setCurrentQuestion(prevState => ({
          ...prevState,
          attachments: {
            ...prevState.attachments,
            files: prevState.attachments.files.map(attachment =>
              attachment.attachment_id === deletedAttachment.id ? { ...attachment, is_deleting: true } : attachment
            )
          }
        }));
        await quizManagementSystem.deleteAttachment(revision.revision_id, selectedQuestionId, deletedAttachment.id);
        setCurrentQuestion(prevState => ({
          ...prevState,
          attachments: {
            ...prevState.attachments,
            files: prevState.attachments.files.filter(({ attachment_id }) => attachment_id !== deletedAttachment.id)
          }
        }));
      } else {
        setFiles(prevState => prevState.filter(({ id }) => id !== deletedAttachment.id));
      }
      setIsAttachmentDialogOpen(true);
    } catch {
      enqueueAlertSnackbar('Failed to delete attachment.', { props: { severity: 'error' } });
    }
  };

  const handleToggleEditAttachmentName = (id, isEdit) => {
    setFiles(prevState =>
      prevState.map(file => {
        if (file.id !== id) return file;
        return { ...file, name: file.currentName, editName: isEdit };
      })
    );
  };

  const handleToggleEditAttachmentDescription = (id, isEdit) => {
    setFiles(prevState =>
      prevState.map(file => {
        if (file.id !== id) return file;
        return { ...file, description: file.currentDescription, editDescription: isEdit };
      })
    );
  };

  const handleChangeAttachmentName = (id, name) => {
    setFiles(prevState => prevState.map(file => (file.id === id ? { ...file, name } : file)));
  };

  const handleChangeAttachmentDescription = (id, description) => {
    setFiles(prevState => prevState.map(file => (file.id === id ? { ...file, description } : file)));
  };

  const handleSaveAttachmentName = id => {
    setFiles(prevState =>
      prevState.map(file => {
        if (file.id !== id) return file;
        return { ...file, currentName: file.name, editName: false };
      })
    );
  };

  const handleSaveAttachmentDescription = id => {
    setFiles(prevState =>
      prevState.map(file => {
        if (file.id !== id) return file;
        return { ...file, currentDescription: file.description, editDescription: false };
      })
    );
  };

  const updateAttachmentProgress = (id, progress) => {
    setFiles(prevState => prevState.map(file => (file.id === id ? { ...file, progress } : file)));
  };

  const handleUploadAttachment = async id => {
    try {
      const attachment = files.find(attachment => attachment.id === id);
      if (!attachment || attachment.isUploading) return;

      setFiles(prevState =>
        prevState.map(file =>
          file.id === id ? { ...file, isUploading: true, editDescription: false, editName: false } : file
        )
      );

      const filename = `${attachment.currentName}.${attachment.extension}`;

      const {
        data: { s3_filename, url, fields }
      } = await quizManagementSystem.getAttachmentUploadPost(revision.revision_id, filename);

      updateAttachmentProgress(id, 35);

      await quiz.uploadAttachment(url, fields, attachment.file);

      updateAttachmentProgress(id, 75);

      const { data: uploadedAttachment } = await quizManagementSystem.saveAttachment(
        revision.revision_id,
        attachment.questionId,
        s3_filename,
        filename,
        attachment.currentDescription
      );
      updateAttachmentProgress(id, 100);

      const { data: response } = await sra.saveSraDocument(fields.key)
      enqueueAlertSnackbar( response.description, { props: { severity: 'success' } });

      setTimeout(() => {
        setFiles(prevState => prevState.filter(({ id }) => id !== attachment.id));
        setCurrentQuestion(prevState => {
          if (prevState.question.id !== attachment.questionId) return prevState;
          const isAttachmentExist = prevState.attachments.files.some(
            ({ attachment_id }) => attachment_id === uploadedAttachment.attachment_id
          );
          if (isAttachmentExist) return prevState;
          return {
            ...prevState,
            attachments: {
              ...prevState.attachments,
              files: [uploadedAttachment, ...prevState.attachments.files]
            }
          };
        });
      }, [500]);
    } catch {
      setFiles(prevState =>
        prevState.map(file => (file.id === id ? { ...file, isUploading: false, progress: 0 } : file))
      );
      enqueueAlertSnackbar('Failed to upload file', { props: { severity: 'error' } });
    }
  };

  const handleCloseAttachmentDialog = () => {
    setIsAttachmentDialogOpen(false);
  };

  if (isloading || !revision) return <Loading />;

  return (
    <Container>
      <QuizViewer
        title={revision.quiz_title}
        showAttachments={Boolean(revision.allow_attachments && currentQuestion.attachments.enabled)}
        showNavigation={Boolean(revision.allow_questions_navigation)}
        disableCategories={!revision.allow_questions_navigation || isloadingQuestion}
        showProgress={Boolean(revision.show_progress)}
        progress={+currentQuestion.progress}
        showCategories={Boolean(revision.show_categories)}
        categories={revision.categories}
        selectedCategoryId={selectedCategoryId}
        onChangeCategory={handleChangeCategory}
        selectedQuestionId={selectedQuestionId}
        steps={steps}
        onAnswer={handleAnswer}
        onNavigateToQuestion={handleNavigateToQuestion}
        onPrevious={handlePrevious}
        onNext={handleNext}
        showPrevious={currentQuestion.question.number > 1}
        submit={Boolean(currentQuestion.question.is_last)}
        answers={answers}
        questionTitle={currentQuestion.question.text}
        loadingQuestion={isloadingQuestion}
        questionDescription={
          <QuestionsInfo info={currentQuestion.question.info} howToAnswer={currentQuestion.question.how_to_answer} />
        }
        disableNavigation={isloadingQuestion}
        disabledNext={isloadingQuestion || (Boolean(currentQuestion.question.is_last) && !isAllAnswered)}
        disablePrevious={isloadingQuestion}
        selectedAnswerId={currentQuestion.user_answer_id}
        onDropFiles={handleDropFiles}
        attachments={attachments}
        onDeleteFile={id => setDeletedAttachmentId(id)}
        onToggleEditFileName={handleToggleEditAttachmentName}
        onChangeFileName={handleChangeAttachmentName}
        onSaveFileName={handleSaveAttachmentName}
        onToggleEditFileDescription={handleToggleEditAttachmentDescription}
        onChangeFileDescription={handleChangeAttachmentDescription}
        onSaveFileDescription={handleSaveAttachmentDescription}
        onUploadAttachment={handleUploadAttachment}
        SubmitButtonProps={
          isNewQuiz
            ? {}
            : {
                startIcon: <ArrowRightIcon />,
                children: 'Confirm Changes',
                width: 160
              }
        }
      />
      <DeleteConfirmationModal
        open={Boolean(deletedAttachmentId)}
        onDelete={handleDeleteAttachment}
        title="Delete item?"
        description="Are you sure you want to delete this item?"
        close={() => setDeletedAttachmentId('')}
        additionalText={false}
      />
      <Dialog
        onClose={handleCloseAttachmentDialog}
        open={isAtatchmentDialogOpen}
        PaperProps={{ sx: { minHeight: 365 } }}
        maxWidth="sm"
        fullWidth
      >
        <Stack alignItems="flex-end" pt={1} pb={0.5} px={1.5}>
          <IconButton onClick={handleCloseAttachmentDialog}>
            <CloseIcon sx={{ fontSize: '2.2rem' }} />
          </IconButton>
        </Stack>
        <DialogContent>
          <Stack mt={7} spacing={1.75} alignItems="center">
            <Avatar sx={theme => ({ width: 70, height: 70, bgcolor: alpha(theme.palette.success.main, 0.2) })}>
              <HappyIcon color="success" sx={{ fontSize: '4.8rem' }} />
            </Avatar>
            <Typography variant="h2" color="success.main">
              Attachment Deleted!
            </Typography>
          </Stack>
        </DialogContent>
      </Dialog>
    </Container>
  );
};

export default SRAQuestionsQuiz;
