/**
 * Generate the Dropzone accept object from the legacy accept file extenstions text.
 *
 * @param {string} fileType File extensions string concatinated with ', '.
 * @returns Object with a common MIME type as keys and an array of file extensions as values.
 *
 * @see [Package documentation](https://react-dropzone.org/#!/Accepting%20specific%20file%20types)
 * @see [Breaking changes for version >=12](https://github.com/react-dropzone/react-dropzone/releases/tag/v12.0.0)
 */
export function generateDropzoneAccept(fileType) {
  return fileType.split(', ').reduce((accept, type) => {
    const key = type.replace('.', '');
    return MIME_TYPES[key] ? { ...accept, [MIME_TYPES[key]]: [type] } : accept;
  }, {});
}

const MIME_TYPES = {
  pdf: 'application/pdf',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  xls: 'application/vnd.ms-excel',
  pfx: 'application/x-pkcs12',
  json: 'application/json',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  dotx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
  csv: 'text/csv',
  txt: 'text/plain',
  jpeg: 'image/jpeg',
  tiff: 'image/tiff',
  png: 'image/png'
};
