// @flow
import React, { type Element, useState } from 'react';
import { Container, TextField, DateField, DragZone, LoadingStyled } from 'components';
import { strClean, hasKey, capitalizeFirstLetter } from 'helpers';

type PageDocumentsInfoModalTypes = {
  record: Object,
  dispatch: {
    fileChanged: Function
  },
  acceptImage: boolean
};

const PageDocumentsInfoModal = ({
  record,
  dispatch,
  disableEditing,
  acceptImage
}: PageDocumentsInfoModalTypes): Element<*> => {
  const [data, setData] = useState(record);
  const onChange = ({ target }) => {
    setData({ ...data, [target.name]: target.value });
    if (target.required) {
      if (!target.value.trim()) target.setCustomValidity('Please fill out this field.');
      else target.setCustomValidity('');
    }
  };

  const onDateChange = (name, value) => {
    setData({...data, dateOrYear:  value});
  };

  const dateOrYear = data && hasKey(data, 'date') ? 'date' : 'year';
  const date = data[`${dateOrYear}`] ? new Date(data[`${dateOrYear}`]).toISOString() : new Date().toISOString();

  const onFileChange = file => {
    setData({ ...data, file });
    dispatch.fileChanged(file);
  };

  if (!record && !data) return <LoadingStyled />;
  return (
    <>
      <Container.Grid mb={2}>
        <Container.Grid item sm={3} xs={3}>
          {data && (
            <DateField
              id={dateOrYear}
              datePicker
              label={capitalizeFirstLetter(dateOrYear)}
              name={dateOrYear}
              value={date}
              format={dateOrYear}
              onChange={value => onDateChange(dateOrYear, value)}
              fullWidth
              disabled={disableEditing}
            />
          )}
        </Container.Grid>
        <TextField
          fullWidth
          source={data.name}
          value={strClean(data.name)}
          id="name"
          label="Name"
          name="name"
          onChange={onChange}
          ml={3}
          required
          disabled={disableEditing}
        />
        <TextField
          fullWidth
          source={data.description}
          value={strClean(data.description)}
          id="description"
          label="Description"
          name="description"
          multiline
          rows={3}
          onChange={onChange}
          ml={3}
          required
          disabled={disableEditing}
        />
      </Container.Grid>
      <DragZone
        title="Upload a file*"
        fileType={`.doc, .docx, .pdf, .txt, .dotx, .csv, .xlsx, .xls ${acceptImage ? ', .jpeg, .tiff, .png' : ''}`}
        size={12}
        record={data}
        dispatch={onFileChange}
        type="file"
        disabledClick
        disabled={disableEditing}
      />
    </>
  );
};

export default PageDocumentsInfoModal;
