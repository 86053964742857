// @flow
import React, { Suspense } from 'react';
import { Route } from 'react-router-dom';
import { ThemeProvider as ThemeProviderStyled } from 'styled-components';
import { Resource, useAuthenticated } from 'react-admin';
import { ThemeProvider, MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import {
  clients,
  company,
  dashboard,
  partner,
  user,
  contentadmin,
  getstarted,
  UserFederated,
  UserSetPassword,
  UserForgotPassword,
  UserNew,
  MicroTrainingPublicView,
  newsfeed,
  UserLogin,
  UserLogout,
  UserSignup,
  Preassessment,
  admin,
  auditlog,
  instantscan,
  reports,
  BSNTeamsAbout,
  HSNTeamsAbout
} from 'apps';
import { Layout, Admin, GlobalStyle, theme, LoadingStyled, Redirect } from 'components';
import { theme as MuiTheme } from 'conf';
import AADAuth from 'apps/shared/directorySync/DirectorySyncAzureActiveDirectoryAuth';
import { authProvider, CacheHandler, dataProvider, reducer, trackingUtils } from 'helpers';
import { QueryClientProvider, QueryClient } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import SRANestedPage from 'components/pages/SRA/SRANestedPage';
import SRANestedSubPage from 'components/pages/SRA/SRANestedSubPage';
import CSVBulkUpload from 'apps/shared/users/pages/CSVBulkUpload';
import DirectorySync from 'apps/shared/users/pages/DirectorySync';
import PartnerWelcomeMessage from 'apps/partner/PartnerWelcomeMessage';
import { ThemeProvider as CLThemeProvider } from '@trustsecurenow/components-library';
import {
  CONTENT_ADMIN_ROOT_PATH,
  CONTENT_ADMIN_MT_NL,
  CONTENT_ADMIN_TEST_TEMPLATES,
  DIRECTORY_SYNC_CLIENTS,
  DIRECTORY_SYNC_MYCOMPANY,
  CSV_BULK_UPLOAD_CLIENTS,
  CSV_BULK_UPLOAD_MYCOMPANY,
  WELCOME_MESSAGE_CLIENTS,
  WELCOME_MESSAGE_MYCOMPANY,
  WELCOME_MESSAGE_CLIENTS_DIRECTORYSYNC,
  WELCOME_MESSAGE_MYCOMPANY_DIRECTORYSYNC
} from './apps/contentadmin/constants';
import ContentAdminCardPage from './apps/contentadmin/pages/ContentAdminCardPage';
import ContentAdminFormPage from './apps/contentadmin/pages/ContentAdminFormPage';
import TestMTNL from './apps/contentadmin/pages/TestMTNL';
import ContentAdminInboxPage from './apps/contentadmin/pages/ContentAdminInboxPage';
import { ADMIN_ROOT_PATH } from './apps/admin/constants';
import { AdminPages, ChangeAdminPage } from './apps/admin/pages';
import { LearnMore as GetStartedLearnMore } from './apps/getstarted/components';
import { GET_STARTED_BASE_PATH } from './apps/getstarted/constants';
import PartnerSetupPage from './apps/partner/PartnerSetupPage';
import { PARTNER_SETUP_BASE_PATH } from './apps/partner/constants';
import ConfigMfaApp from './apps/user/ConfigMfaApp';
import MfaValidation from './apps/user/MfaValidation';
import PhishingDirectMailboxDelivery from './apps/shared/phishingReports/phishingTable/PhishingDirectMailboxDelivery';
import { REPORTS_BASE_PATH } from './apps/reports/constants';
import QuickSightDashboard from 'apps/reports/components/QuickSightDashboard';
import CreateClientPage from 'apps/clients/CreateClientPage';

const TestTemplates = React.lazy(() => {
  return import('apps/contentadmin/pages/TestTemplates');
});

trackingUtils.initialize();

const env = process.env.REACT_APP_BUILD_ENV;
const queryClient = new QueryClient();

const PrivateRoute = props => {
  useAuthenticated(); // redirects to login if not authenticated
  return <Route {...props} />;
};

const App = () => {
  return (
    <>
      <CacheHandler duration={60000} />
      <CLThemeProvider>
        <MuiThemeProvider theme={createMuiTheme(MuiTheme)}>
          <ThemeProviderStyled theme={{ ...MuiTheme, ...theme }}>
            <QueryClientProvider client={queryClient}>
              {env === 'dev' && <ReactQueryDevtools initialIsOpen={false} />}
              <GlobalStyle />
              <ThemeProvider>
                <Admin
                  layout={Layout}
                  dataProvider={dataProvider}
                  authProvider={authProvider}
                  loginPage={UserLogin}
                  customReducers={reducer}
                  customRoutes={[
                    <Route key="login" exact path="/" component={UserLogin} noLayout />,
                    <Route key="signup" exact path="/signup" component={UserSignup} noLayout />,
                    <PrivateRoute key="configMfaApp" exact path="/configmfaapp" component={ConfigMfaApp} noLayout />,
                    <PrivateRoute key="MfaValidation" exact path="/mfavalidation" component={MfaValidation} noLayout />,
                    <Route key="logout" exact path="/logout" component={UserLogout} noLayout />,
                    <Route key="federated" exact path="/federated" component={UserFederated} noLayout />,
                    <Route key="setPassword" exact path="/setPassword" component={UserSetPassword} noLayout />,
                    <Route key="forgotPassword" exact path="/forgotPassword" component={UserForgotPassword} noLayout />,
                    <Route key="newUser" exact path="/newUser" component={UserNew} noLayout />,
                    <Route
                      key="MicroTrainingPublicView"
                      exact
                      path="/MicroTrainingPublicView"
                      component={MicroTrainingPublicView}
                      noLayout
                    />,
                    <PrivateRoute
                      key="welcomeMessage_client"
                      exact
                      path={`${WELCOME_MESSAGE_CLIENTS}/:id`}
                      component={PartnerWelcomeMessage}
                    />,
                    <PrivateRoute
                      key="welcomeMessage_mycompany"
                      exact
                      path={`${WELCOME_MESSAGE_MYCOMPANY}/:id`}
                      component={PartnerWelcomeMessage}
                    />,
                    <PrivateRoute
                      key="welcomeMessage_clients_directorySync"
                      exact
                      path={`${WELCOME_MESSAGE_CLIENTS_DIRECTORYSYNC}/:id`}
                      component={PartnerWelcomeMessage}
                    />,
                    <PrivateRoute
                      key="welcomeMessage_mycompany_directorySync"
                      exact
                      path={`${WELCOME_MESSAGE_MYCOMPANY_DIRECTORYSYNC}/:id`}
                      component={PartnerWelcomeMessage}
                    />,
                    <PrivateRoute
                      key="csvbulkupload"
                      exact
                      path={`${CSV_BULK_UPLOAD_CLIENTS}/:id`}
                      component={CSVBulkUpload}
                    />,
                    <PrivateRoute
                      key="csvbulkupload"
                      exact
                      path={`${CSV_BULK_UPLOAD_MYCOMPANY}/`}
                      component={CSVBulkUpload}
                    />,
                    <PrivateRoute
                      key="directorysync"
                      exact
                      path={`${DIRECTORY_SYNC_CLIENTS}/:id`}
                      component={DirectorySync}
                    />,
                    <PrivateRoute
                      key="directorysync"
                      exact
                      path={`${DIRECTORY_SYNC_MYCOMPANY}/`}
                      component={DirectorySync}
                    />,
                    <Route key="preassessment" exact path="/Preassessment" component={Preassessment} noLayout />,
                    <Route key="aadAuth" path="/aadAuth" component={AADAuth} noLayout />,
                    <Route
                      key="phishingAADAuth"
                      path="/phishingAADAuth"
                      component={PhishingDirectMailboxDelivery}
                      noLayout
                    />,
                    <Route
                      key="testTemplate"
                      path={`${CONTENT_ADMIN_ROOT_PATH}/${CONTENT_ADMIN_TEST_TEMPLATES}`}
                      component={() => (
                        <Suspense fallback={<LoadingStyled style={{ margin: '0 auto' }} />}>
                          <TestTemplates />
                        </Suspense>
                      )}
                    />,
                    <Route
                      key="contentadmin"
                      path={`${CONTENT_ADMIN_ROOT_PATH}/${CONTENT_ADMIN_MT_NL}`}
                      component={TestMTNL}
                    />,
                    <PrivateRoute key="adminPages" exact path={`${ADMIN_ROOT_PATH}/:page`} component={AdminPages} />,
                    <PrivateRoute
                      key="adminPagesMethods"
                      exact
                      path={`${ADMIN_ROOT_PATH}/:page/:method`}
                      component={ChangeAdminPage}
                    />,
                    <PrivateRoute
                      key="adminPagesInfo"
                      exact
                      path={`${ADMIN_ROOT_PATH}/:page/:method/:id`}
                      component={ChangeAdminPage}
                    />,
                    <PrivateRoute
                      key="contentadminpages"
                      exact
                      path={`${CONTENT_ADMIN_ROOT_PATH}/inbox`}
                      component={ContentAdminInboxPage}
                    />,
                    <PrivateRoute
                      key="contentadminpages"
                      exact
                      path={`${CONTENT_ADMIN_ROOT_PATH}/:page`}
                      component={ContentAdminCardPage}
                    />,
                    <PrivateRoute
                      key="contentadmincreateform"
                      exact
                      path={`${CONTENT_ADMIN_ROOT_PATH}/:page/:method/`}
                      component={ContentAdminFormPage}
                    />,
                    <PrivateRoute
                      key="contentadmineditform"
                      exact
                      path={`${CONTENT_ADMIN_ROOT_PATH}/:page/:method/:id`}
                      component={ContentAdminFormPage}
                    />,
                    <PrivateRoute
                      key="getStartedLearnMore"
                      exact
                      path={`${GET_STARTED_BASE_PATH}/:page`}
                      component={GetStartedLearnMore}
                    />,
                    <PrivateRoute
                      key="partnerSetup"
                      exact
                      path={`${PARTNER_SETUP_BASE_PATH}/:page`}
                      component={PartnerSetupPage}
                    />,
                    <PrivateRoute key="sraCompany" exact path="/myCompany/sra/:page" component={SRANestedPage} />,
                    <PrivateRoute key="sraClients" exact path="/clients/sra/:id/:page" component={SRANestedPage} />,
                    <PrivateRoute
                      key="sraCompanyEdit"
                      exact
                      path="/myCompany/sra/:page/:reportId"
                      component={SRANestedPage}
                    />,
                    <PrivateRoute
                      key="sraClientsEdit"
                      exact
                      path="/clients/sra/:id/:page/:reportId"
                      component={SRANestedPage}
                    />,
                    <PrivateRoute
                      key="sraCompany"
                      exact
                      path="/myCompany/sra/:page/:reportId/:subpage"
                      component={SRANestedSubPage}
                    />,
                    <PrivateRoute
                      key="sraClients"
                      exact
                      path="/clients/sra/:id/:page/:reportId/:subpage"
                      component={SRANestedSubPage}
                    />,
                    <PrivateRoute
                      key="reportsDashboard"
                      exact
                      path={`${REPORTS_BASE_PATH}/:dashboard`}
                      component={QuickSightDashboard}
                    />,
                    <PrivateRoute key="createNewClient" exact path="/clients/create" component={CreateClientPage} />,
                    <Route key="bsnteamsappprivacy" exact path="/bsnteamsappprivacy" noLayout>
                      <Redirect link="https://www.breachsecurenow.com/privacy" />
                    </Route>,
                    <Route key="bsnteamsapptermsofuse" exact path="/bsnteamsapptermsofuse" noLayout>
                      <Redirect link="https://www.breachsecurenow.com/terms-and-conditions" />
                    </Route>,
                    <Route key="bsnteamsappabout" exact path="/bsnteamsappabout" component={BSNTeamsAbout} noLayout />,
                    <Route key="hsnteamsappprivacy" exact path="/hsnteamsappprivacy" noLayout>
                      <Redirect link="https://www.hipaasecurenow.com/privacy-hipaa-secure-now" />
                    </Route>,
                    <Route key="hsnteamsapptermsofuse" exact path="/hsnteamsapptermsofuse" noLayout>
                      <Redirect link="https://www.hipaasecurenow.com/terms-of-service-hipaa-secure-now" />
                    </Route>,
                    <Route key="hsnteamsappabout" exact path="/hsnteamsappabout" component={HSNTeamsAbout} noLayout />
                  ]}
                >
                  <Resource {...dashboard} />
                  <Resource {...newsfeed} />
                  <Resource {...company} />
                  <Resource {...clients} />
                  <Resource {...partner} />
                  <Resource {...reports} />
                  <Resource {...getstarted} />
                  <Resource {...user} />
                  <Resource {...admin} />
                  <Resource {...contentadmin} />
                  <Resource {...auditlog} />
                  <Resource {...instantscan} />
                </Admin>
              </ThemeProvider>
            </QueryClientProvider>
          </ThemeProviderStyled>
        </MuiThemeProvider>
      </CLThemeProvider>
    </>
  );
};

export default App;
