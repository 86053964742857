import React, { useEffect, useState } from 'react';
import { Close, Filter, Plus } from 'components/icons';
import { Button, Dialog, DialogContent, DialogTitle, IconButton, TextField, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { dataProvider } from 'helpers';
import { useId, useLocation } from 'hooks';
import { Autocomplete } from '@material-ui/lab';
import useHasInternet from 'hooks/useHasInternet';
import { useTableList } from 'components/tablelist/TableListContext';
import { cloneDeep } from 'lodash';

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 5px;
`;

const DialogTitleContainer = styled.div`
  && {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const StyledAutoComplete = styled(Autocomplete)`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const StyledLabelContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const FilterButton = styled(Button)`
  && {
    height: 52px;
    background-color: var(--colorBaseBase);
    &:focus,
    &:active {
      background-color: var(--colorBaseBase);
    }
  }
`;

const initialFilters = [
  {
    label: 'Status',
    key: 'activated',
    value: null,
    options: [
      {
        label: 'Active',
        value: 'true'
      },
      {
        label: 'Inactive',
        value: 'false'
      }
    ]
  },
  {
    label: 'Deleted',
    key: 'deleted',
    value: null,
    options: [
      {
        label: 'Yes',
        value: 'true'
      },
      {
        label: 'No',
        value: 'false'
      }
    ]
  },
  {
    label: 'ESS',
    key: 'ess',
    value: null,
    options: [
      { label: 'Low', value: 'low' },
      { label: 'Medium', value: 'medium' },
      { label: 'High', value: 'high' }
    ]
  },
  {
    label: 'Group Role',
    key: 'group_role',
    value: null,
    options: []
  }
];

const FilterDialog = ({ open, handleClose, handleChange, filters }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      style={{ backgroundColor: 'rgba(8, 47, 73, 0.80)' }}
      fullWidth
      maxWidth="md"
    >
      <DialogTitle id="actions-dialog-title">
        <DialogTitleContainer>
          <Typography variant="h4">Filter By</Typography>
          <IconButton aria-label="close" onClick={handleClose}>
            <Close />
          </IconButton>
        </DialogTitleContainer>
      </DialogTitle>
      <DialogContent>
        {(filters ?? []).map((filter, index) => {
          return (
            <StyledAutoComplete
              id={`filter-${filter.label}`}
              fullWidth
              value={filter.options.filter(option => option.value === filter.value)[0] || null}
              loading={filter.options.length === 0}
              options={filter.options}
              getOptionLabel={option => option.label}
              forcePopupIcon={false}
              closeIcon={<Close />}
              onChange={(e, option) => {
                if (option) {
                  return handleChange(filter.key, option.value, index);
                }
                handleChange(filter.key, null, index);
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  label={
                    <StyledLabelContainer>
                      <Plus />
                      &nbsp;&nbsp;{filter.label}
                    </StyledLabelContainer>
                  }
                  InputProps={{
                    ...params.InputProps,
                    disableUnderline: true
                  }}
                />
              )}
            />
          );
        })}
      </DialogContent>
    </Dialog>
  );
};

const FilterModal = ({ onChange }) => {
  const [open, setOpen] = React.useState(false);
  const { enableFilters } = useTableList();
  const { app, tab, item } = useLocation();
  const clientId = useId();
  const [filters, setFilters] = useState(null);

  useEffect(() => {
    const newFilter = cloneDeep(initialFilters);
    setFilters(enableFilters?.ess ? newFilter : newFilter.filter(filter => filter.key !== 'ess'));
  }, [enableFilters]);

  const hasInternet = useHasInternet();

  useEffect(() => {
    dataProvider
      .getList(app === 'myCompany' ? 'clients' : app, `userGroups/${String(item || clientId)}`, {})
      .then(({ data: resData }) => {
        hasInternet(() => {
          setFilters(val => {
            const newFilters = [...val];
            newFilters.filter(filter => filter.key === 'group_role')[0].options = resData.map(group => {
              return { value: group.group_id, label: group.name };
            });
            return [...newFilters];
          });
        });
      });
  }, [item, clientId, app, hasInternet]);

  const handleClose = () => {
    setOpen(false);
  };
  const handleChange = async (key, value, index) => {
    const newFilters = filters.slice();
    newFilters[index].value = value;
    setFilters(newFilters.slice());
    const filter = {};
    newFilters.forEach(obj => {
      if (obj.key === 'activated' || obj.key === 'deleted') {
        filter[obj.key] = obj.value || '';
      } else {
        filter[obj.key] = obj.value ? [obj.value] : [];
      }
    });
    return onChange({ ...filter });
  };

  return (
    <Container>
      <FilterButton
        icon
        variant="contained"
        color="primary"
        onClick={() => {
          setOpen(old => !old);
        }}
      >
        <Filter />
      </FilterButton>
      <FilterDialog
        open={open}
        handleClose={handleClose}
        handleChange={handleChange}
        filters={filters}
        setFilters={setFilters}
      />
    </Container>
  );
};

export default FilterModal;
