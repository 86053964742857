import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useId, useLocation } from 'hooks';
import { hasKey, dataProvider, getStorage } from 'helpers';
import useHasInternet from 'hooks/useHasInternet';
import { BSN_SET_CLIENT_ACCESS, BSN_SET_CLIENT_NEW_ACCESS, BSN_SET_ANY } from 'conf';

function useClientAccess({ id, defaultAccess = null }) {
  const partnerId = useId({ key: 'partnerId' });
  const { app, tab } = useLocation();
  const updatedAccess = useSelector(state => state.bsn.clients.clientUpdatedAccess);
  const clientNewAccess = useSelector(state => state.bsn.clients.clientNewAccess);
  const userProfile = useSelector(state => state.bsn.user.profile);
  const adminAccess = useSelector(({ admin }) => {
    const { data } = admin.resources.clients;
    return hasKey(data, String(id)) ? data[id].access : null;
  });
  const isMaskMode = Boolean(getStorage('maskMode', true));
  const initialAccess = adminAccess ? { ...adminAccess, admin: isMaskMode } : adminAccess || defaultAccess;
  const [access, setAccess] = useState(initialAccess);
  const hasInternet = useHasInternet();
  const dispatchRx = useDispatch();

  const setClientAccess = newAccess => {
    setAccess({ ...newAccess, admin: isMaskMode });
  };

  useEffect(() => {
    if (!userProfile?.id) return;
    if (access === null && id && app === 'clients') {
      dataProvider.getOne('clients', `list/${partnerId}`, { _filter: `id:${String(id)}` }).then(d => {
        hasInternet(() => {
          if (d && d.data && d.data?.clients.length > 0) {
            setClientAccess(d.data.clients[0].access);
            dispatchRx({
              type: BSN_SET_ANY,
              payload: {
                user: {
                  profile: {
                    is_enterprise_partner: d.data?.clients[0]?.enterprise_partner ?? 0
                  }
                }
              }
            });
            dispatchRx({
              type: BSN_SET_ANY,
              payload: {
                clients: {
                  client_product_type: d.data?.clients[0]?.product_type ?? 0,
                  isActive: d.data?.clients[0]?.active
                }
              }
            });
          }
        });
      });
    } else if (access && id && app === 'clients' && tab === 'users') {
      dataProvider.getOne('clients', `list/${partnerId}`, { _filter: `id:${String(id)}` }).then(d => {
        if (d && d.data && d.data?.clients.length > 0) {
          dispatchRx({
            type: BSN_SET_ANY,
            payload: {
              clients: {
                client_product_type: d.data?.clients[0]?.product_type ?? 0
              }
            }
          });
        }
      });
    } else if (updatedAccess) {
      setClientAccess(updatedAccess);
    }
  }, [adminAccess, id, partnerId, updatedAccess, app, userProfile?.id]);

  useEffect(() => {
    return () => {
      if (updatedAccess) {
        dispatchRx({
          type: BSN_SET_CLIENT_ACCESS,
          payload: { access: null }
        });
      }
    };
  }, [updatedAccess]);

  useEffect(() => {
    return () => {
      if (clientNewAccess) {
        dispatchRx({
          type: BSN_SET_CLIENT_NEW_ACCESS,
          payload: null
        });
      }
    };
  }, [clientNewAccess, dispatchRx]);

  return clientNewAccess || access || {};
}

export default useClientAccess;
