import React from 'react';
import * as quizManagementSystem from 'helpers/apis/services/quizManagementSystem';
import { getAnalyzedUserState } from './helpers';

const loadQuestion = async (dispatch, callback) => {
  /* callback: a function that returns a promise that resolves to the question data */

  dispatch({ type: 'SET_STATE', payload: { isQuestionLoading: true } });
  const { data: question } = await callback();
  dispatch({ type: 'SET_QUESTION', payload: { question } });

  return question;
};

const initialState = {
  isQuizLoading: true,
  isQuestionLoading: true,
  isSubmittingAnswer: false
};

const useQuiz = ({ quizId: quiz_id, quizToken: quiz_token, onQuizEnd, onError }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const {
    revision_id,
    questionId,
    prevQuestionId,
    nextQuestionId,
    isAnswerSubmitted,
    allowQuestionsNavigation,
    selectedAnswerId,
    questionsStatus
  } = state;

  React.useEffect(() => {
    if (!quiz_id || !quiz_token) {
      console.error('quiz_id or quiz_token doesnt exist', { quiz_id, quiz_token });
      onError();
      return;
    }
    startQuiz({ quiz_token, quiz_id });
  }, [quiz_id, quiz_token]);

  const startQuiz = React.useCallback(async ({ quiz_token, quiz_id }) => {
    try {
      const userState = await getAnalyzedUserState({ quiz_token, quiz_id });

      let revisionResponse;
      switch (userState.type) {
        case 'take':
          // create a new revision using quiz_id
          revisionResponse = await quizManagementSystem.createQuizRevision(quiz_id);
          break;
        case 'retake':
          // create a new revision using quiz_id
          revisionResponse = await quizManagementSystem.retakeQuizRevision(quiz_id);
          break;
        case 'resume':
          // userState data has the revision_id, resume revision
          const revision_id = userState.data?.info?.revision_id;
          revisionResponse = await quizManagementSystem.resumeQuizRevision(revision_id);
          break;
        case 'done':
          dispatch({ type: 'SET_STATE', payload: { isQuizLoading: false, isQuizFinished: true } });
          onQuizEnd(userState.data); // TODO: make sure score is returned in userState in case quiz completed
          break;
        case 'error':
          return;
        default:
          return;
      }

      const revision = revisionResponse?.data;

      // we should have revision data if userState request was successful
      dispatch({ type: 'SET_REVISION', payload: { revision } });

      // if we have the revision data, get current question
      const revision_id = revision?.revision_id;
      if (revision_id) {
        await loadQuestion(dispatch, () => quizManagementSystem.getCurrentQuestion(revision_id));
      }

      // current question is done loading
      dispatch({ type: 'SET_STATE', payload: { isQuizLoading: false } });
    } catch (err) {
      console.error(err);
      onError();
    }
  }, []);

  const answerQuestion = React.useCallback(
    async ({ answer_id }) => {
      try {
        dispatch({ type: 'ANSWER_QUESTION', payload: { answer_id } });
        await quizManagementSystem.answerQuestion(revision_id, questionId, answer_id);
        dispatch({ type: 'ANSWER_SUBMITTED' });
      } catch (err) {
        console.error(err);
        dispatch({ type: 'UNANSWER_QUESTION' });
        onError();
      }
    },
    [quiz_token, revision_id, questionId, onQuizEnd]
  );

  const nextQuestion = React.useCallback(async () => {
    try {
      dispatch({ type: 'SET_STATE', payload: { questionId: nextQuestionId } });
      await loadQuestion(dispatch, () => {
        return quizManagementSystem
          .nextQuestion(revision_id)
          .then(() => quizManagementSystem.getCurrentQuestion(revision_id));
      });
    } catch (err) {
      console.error(err);
      onError();
    }
  }, [quiz_token, revision_id, nextQuestionId]);

  const gotoQuestion = React.useCallback(
    async ({ question_id }) => {
      try {
        dispatch({ type: 'SET_STATE', payload: { questionId: question_id } });
        await loadQuestion(dispatch, () => {
          return quizManagementSystem.setCurrentQuestion(revision_id, question_id);
        });
      } catch (err) {
        console.error(err);
        onError();
      }
    },
    [quiz_token, revision_id]
  );

  const prevQuestion = React.useCallback(async () => {
    await gotoQuestion({ question_id: prevQuestionId });
  }, [prevQuestionId, gotoQuestion]);

  const submitQuiz = React.useCallback(async () => {
    try {
      dispatch({
        type: 'SET_STATE',
        payload: {
          isQuizFinished: true,
          isQuizLoading: true
        }
      });
      const { data } = await quizManagementSystem.endSRAQuiz(revision_id);
      onQuizEnd(data);
    } catch (err) {
      console.error(err);
      onError();
    }
  }, [quiz_token, revision_id, onQuizEnd]);

  const isSubmitDisabled = React.useMemo(() => {
    // disable if current question is not answered
    if (!isAnswerSubmitted) return true;
    // disable if there are any other unanswered question
    return questionsStatus.some(q => !q.is_answered);
  }, [isAnswerSubmitted, questionsStatus]);

  const isNextDisabled = React.useMemo(() => {
    // check if navigation between questions without answering is allowed
    if (allowQuestionsNavigation) {
      // disable if user answered but answer is not submitted yet
      return !!selectedAnswerId && !isAnswerSubmitted;
    } else {
      // disable if user didn't answer or answer is not submitted yet
      return !selectedAnswerId || !isAnswerSubmitted;
    }
  }, [isAnswerSubmitted, allowQuestionsNavigation, selectedAnswerId]);

  const isActionsDisbled = state.isQuizLoading || state.isQuestionLoading || state.isSubmittingAnswer;

  return {
    state,
    isSubmitDisabled,
    isNextDisabled,
    isActionsDisbled,
    handle: {
      answerQuestion,
      nextQuestion,
      prevQuestion,
      gotoQuestion,
      submitQuiz
    }
  };
};

function reducer(state, action = {}) {
  const { type, payload = {} } = action;

  switch (type) {
    case 'SET_STATE': {
      return { ...state, ...payload };
    }
    case 'SET_REVISION': {
      /*
      revision = {
        "allow_questions_navigation": 0, // use to show/hide stepper
        "quiz_title": "Test",
        "revision_id": "VFdwbk1rMW5QVDA9", // use this from now on
        "questions": [{question_id, is_answered}]
        "categories_count": 0,
        "record_question_timestamp": 0,
        "show_progress": 0,
        "randomize_questions": 0,
        "show_categories": 0,

        "has_score": 1,
        "show_score": 1,
        "questions_count": 2, // no of questions in stepper
      }      
      */
      const { revision = {} } = payload;
      return {
        ...state,
        revision_id: revision.revision_id,
        quizTitle: revision.quiz_title,

        questionsStatus: revision.questions,
        allowQuestionsNavigation: !!revision.allow_questions_navigation,
        isRandomQuesitons: !!revision.randomize_questions,
        categoriesCount: revision.categories_count,
        showCategories: !!revision.show_categories,
        showTimeElapsed: !!revision.record_question_timestamp,
        showProgress: !!revision.show_progress
      };
    }
    case 'ANSWER_QUESTION': {
      const { answer_id } = payload;
      const { questionId } = state;

      const newState = {
        ...state,
        selectedAnswerId: answer_id,
        isSubmittingAnswer: true,
        isAnswerSubmitted: false,
        // mark the question as answered in questions status list to reflect on stepper
        questionsStatus: state.questionsStatus.map(status => {
          return status.question_id === questionId ? { ...status, is_answered: 1 } : status;
        })
      };

      return newState;
    }
    case 'UNANSWER_QUESTION': {
      const { questionId } = state;

      const newState = {
        ...state,
        selectedAnswerId: null, // clear answer
        isSubmittingAnswer: false,
        isAnswerSubmitted: false,
        // mark the question as unanswered in questions status list to reflect on stepper
        questionsStatus: state.questionsStatus.map(status => {
          return status.question_id === questionId ? { ...status, is_answered: 0 } : status;
        })
      };

      return newState;
    }
    case 'ANSWER_SUBMITTED': {
      return {
        ...state,
        isSubmittingAnswer: false,
        isAnswerSubmitted: true
      };
    }
    case 'SET_QUESTION': {
      /*
      question = {
          "answers": [{answer, answer_id}],
          "category": {},
          "progress": null,
          "question": {id, is_last, number, text},
          "time_elapsed": "0",
          "user_answer_id": null
      }
      */
      const { question = {} } = payload;

      const questionId = question.question?.id;
      const selectedAnswerId = question.user_answer_id;

      const questionsStatus = state.questionsStatus;
      const questionIndex = questionsStatus.findIndex(q => q.question_id === questionId);
      const questionStatus = questionsStatus[questionIndex];
      const isAnswerSubmitted = !!questionStatus?.is_answered;
      const questionNumber = state.isRandomQuesitons ? questionIndex + 1 : question.question?.number;

      const isFirstQuestion = questionIndex === 0;
      const isLastQuestion = !!question.question?.is_last;

      const prevQuestionId = isFirstQuestion ? null : questionsStatus[questionIndex - 1]?.question_id;
      const nextQuestionId = isLastQuestion ? null : questionsStatus[questionIndex + 1]?.question_id;

      const timeElapsed = question.time_elapsed ? +question.time_elapsed : 0;

      return {
        ...state,
        isQuestionLoading: false,

        questionId,
        selectedAnswerId,
        prevQuestionId,
        nextQuestionId,

        questionIndex,
        questionNumber,
        questionAnswers: question.answers,
        questionText: question.question?.text,
        questionCategoryName: question.category?.name,
        questionCategoryNumber: question.category?.number,

        isAnswerSubmitted,
        isFirstQuestion,
        isLastQuestion,

        quizProgress: question.progress,
        timeElapsed
      };
    }

    default:
      return state;
  }
}

export default useQuiz;
