import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { LoadingStyled } from 'components';
import * as sra from 'helpers/apis/services/sra';
import { enqueueAlertSnackbar } from '@trustsecurenow/components-library';
import { NameAndLocation, Network, Email, MediaAndDevices, System, AdditionalInformation, EmrAndEhr } from './tabs';
import { TabsHeader, TabsFixed, TabButton } from '../../../tabs/ComponentTypes';
import _ from 'lodash';

const availableTabs = [
  {
    name: 'name_and_location',
    label: 'Name and Location',
    Component: NameAndLocation
  },
  {
    name: 'network',
    label: 'Network',
    Component: Network
  },
  {
    name: 'emr_ehr',
    label: 'EMR/EHR',
    Component: EmrAndEhr
  },
  {
    name: 'email',
    label: 'Email',
    Component: Email
  },
  {
    name: 'media_and_devices',
    label: 'Media and Devices',
    Component: MediaAndDevices
  },
  {
    name: 'system',
    label: 'System',
    Component: System
  },
  {
    name: 'additional_info',
    label: 'Additional Information',
    Component: AdditionalInformation
  }
];

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

const OrganizationProfile = ({ clientId, isHSN, handleClose, state, setState, setOriginalState, originalState }) => {
  const [currentTab, setCurrentTab] = useState(availableTabs[0].name);
  const [loading, setLoading] = useState(false);
  // removing the emr/ehr tab, if the client is not HSN
  const filteredAvailableTabs = isHSN ? availableTabs : availableTabs.filter(tab => tab.name !== 'emr_ehr');

  // Get organization profile data
  const fetchOrgProfile = useCallback(async () => {
    setLoading(true);
    return sra
      .getClientOrganizationProfile(clientId)
      .then(res => {
        setState(res.data);
        setOriginalState(res.data);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [clientId, setState, setOriginalState]);

  useEffect(() => {
    if (!state) {
      fetchOrgProfile();
    }
  }, [state, fetchOrgProfile]);

  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  // Save organization profile data
  const onSave = (action, currentTabIndex, newState) => {
    setLoading(true);

    const stateData = {
      ...state,
      ...(newState && { ...newState })
    };

    sra
      .setClientOrganizationProfile(clientId, stateData)
      .then(fetchOrgProfile)
      .then(() => {
        enqueueAlertSnackbar('Saved successfully.', { props: { severity: 'success' } });
        if (action === 'next') {
          handleChangeTab({}, filteredAvailableTabs[currentTabIndex + 1].name);
        }
        if (action === 'close') {
          handleClose();
        }
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) return <LoadingStyled />;

  const compareStates = () => {
    // All key/value pairs must be equal, however there are some special cases
    // based on how the backend represents data
    return _.isEqualWith(state, originalState, (val, originalVal) => {
      // null and "" are considered equal as they both represent empty values to the backend
      if ((val === '' || val === null) && (originalVal === '' || originalVal === null)) {
        return true;
      }
      // strings that represent numbers should be considered equal to numbers
      // e.g. "1" and 1 should be equal
      if (typeof val === 'string' && typeof originalVal === 'number') {
        return val === originalVal.toString();
      }
      if (typeof val === 'number' && typeof originalVal === 'string') {
        return val.toString() === originalVal;
      }
      // for OS check boxes, 0 and null represent false
      if(val === 0 && (originalVal === undefined || originalVal === null)) {
        return true;
      }
      // for second location check boxes, "No" and "" represent false
      if(val === 'No' && originalVal === '') {
        return true;
      }
    });
  };

  const dirty = !compareStates();

  return (
    <Box mt={3}>
      <TabsFixed>
        <TabsHeader variant="scrollable" scrollButtons="desktop" value={currentTab} onChange={handleChangeTab}>
          {filteredAvailableTabs.map(tab => {
            return <TabButton value={tab.name} label={tab.label} />;
          })}
        </TabsHeader>
      </TabsFixed>
      {filteredAvailableTabs.map((tab, index) => {
        const { name, Component } = tab;
        return (
          <TabPanel value={currentTab} index={name}>
            <Component
              state={state || {}}
              setState={setState}
              onSave={onSave}
              clientId={clientId}
              isHSN={isHSN}
              handleClose={handleClose}
              currentTabIndex={index}
              dirty={dirty}
            />
          </TabPanel>
        );
      })}
    </Box>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  index: PropTypes.any.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any.isRequired
};

OrganizationProfile.propTypes = {
  clientId: PropTypes.string.isRequired,
  isHSN: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  state: PropTypes.oneOfType([null, PropTypes.object]).isRequired,
  setState: PropTypes.func.isRequired
};
export default OrganizationProfile;
