import React, { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'hooks';
import { Container } from 'components';
import { Stack } from '@trustsecurenow/components-library';
import MfaModal from './modals/MfaModal';
import StatusButton from '../darkWebMonitoring/StatusButton';
import SingleSignOnSettingsModal from './modals/SingleSignOnSettingsModal';
import { BSN_SET_ANY } from 'conf';
import clientsApi from 'helpers/apis/clients';
import WelcomeMessageStatusButton from 'apps/contentadmin/components/shared/WelcomeMessageStatusButton';
import { DirectorySyncProvider } from '../directorySync/DirectorySyncContext';

const TopToolbar = () => {
  const { app, item } = useLocation();
  const clientId = useLocation('clientId');

  const dispatch = useDispatch();
  const userRole = useSelector(({ bsn }) => bsn?.user?.profile?.user_role);
  const user_profile = useSelector(s => s?.bsn?.user?.profile);
  const { isActive } = useSelector(s => s?.bsn?.clients ?? {});
  const buttonsState = useSelector(({ bsn }) => bsn?.user?.profile?.shared?.buttonsStatus);
  const isAdmin = userRole === 'Administrator';
  const isPartnerAdmin = userRole === 'Partner Administrator';
  const isManagerAdmin = userRole === 'Manager Admin';
  const isAdminOrPartnerAdmin = isAdmin || isPartnerAdmin;
  const [isWelcomeMsgDialogOpened, setIsWelcomeMsgDialogOpened] = useState(false);

  const getOperationButtonsStatus = useCallback(async () => {
    try {
      const status = await clientsApi.getOperationButtonsStatus(clientId.item);
      dispatch({
        type: BSN_SET_ANY,
        payload: {
          user: {
            profile: {
              shared: {
                buttonsStatus: status?.data
              }
            }
          }
        }
      });
    } catch (error) {
      console.error(error);
    }
  }, [clientId.item]);

  useEffect(() => {
    getOperationButtonsStatus();
  }, [getOperationButtonsStatus]);

  return isAdminOrPartnerAdmin || (isManagerAdmin && Boolean(user_profile.dir_sync)) ? (
    <Container.Paper mb={1} style={{ borderRadius: '5px' }}>
      <Stack useFlexGap flexWrap={'wrap'} direction={'row'} spacing={2} ml={4} py={1}>
        {(!item || isActive) && (
        <StatusButton
          style={{ marginRight: 5 }}
          status={buttonsState?.directory_sync_enabled ? 'active' : 'inactive'}
          label="Directory Sync"
          onClick={() => {
            window.location.href = `/#/${app}/directorySync/${item || ''}`;
          }}
          inactiveOnClick={() => {
            window.location.href = `/#/${app}/directorySync/${item || ''}`;
          }}
        />
        )}
        <SingleSignOnSettingsModal />
        <MfaModal />
        <DirectorySyncProvider hideLoading>
          <WelcomeMessageStatusButton
            tooltipPlacement="right"
            isWelcomeMsgDialogOpened={isWelcomeMsgDialogOpened}
            setIsWelcomeMsgDialogOpened={setIsWelcomeMsgDialogOpened}
          />
        </DirectorySyncProvider>
      </Stack>
    </Container.Paper>
  ) : null;
};

export default TopToolbar;