import apiClient from '../apiClient/apiClient';

import { getStorage } from '../storage';
import trainingInformation from './trainingInformation';
import { buildQueryString } from 'helpers/utils';
import { dataParams } from '../index';

const env = process.env.REACT_APP_BUILD_ENV;
const base = process.env.REACT_APP_BASE_URL;

const baseURL = (apiGWID) => `https://${apiGWID}.${base}/${env}`;

const training = {};

training.getRequestHeaders = () => ({
  'Content-Type': 'application/json',
  Authorization: localStorage.getItem('idToken')
})

training.trainingFilters = (id) => {
  const apiGWID = process.env.REACT_APP_BASE_URL_16;
  const requestOptions = {
    url: `${baseURL(apiGWID)}/myCompany/trainingFilter/${id}`,
    headers: training.getRequestHeaders(),
  };
  return apiClient.get(requestOptions);
}

training.trainingReport = (id, reportType, params) => {
  const apiGWID = process.env.REACT_APP_BASE_URL_16;
  const requestOptions = {
    url: `${baseURL(apiGWID)}/clients/${reportType}/${id}`,
    headers: training.getRequestHeaders(),
    params: dataParams(params),
  };
  return apiClient.get(requestOptions);
}

training.trainingModal = (trainingType, id, params) => {
  const apiGWID = process.env.REACT_APP_BASE_URL_16;

  const requestOptions = {
    url: `${baseURL(apiGWID)}/clients/${trainingType}/${id}`,
    headers: training.getRequestHeaders(),
    params: dataParams(params),
  };
  return apiClient.get(requestOptions);
}


export default training;
